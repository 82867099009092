import { IMAGES } from "./images";
import { Variables } from "./variable";
import * as _ from 'radash'

export const ENV = 'prod' // it should be dev or prod

export const ROUTES = {
    LOGIN: 'login',
    SEARCH: 'search',
    HISTORY: 'history',
    PROFILE: 'profile',
    VIDEO_PLAYING: 'video',
    SEARCH_RESULT: 'search',
    SPEAKER_PROFILE: 'profile',
    BRAND_PROFILE: 'brand',
    PRIVACY_POLICY: 'privacy-policy',
    SEARCH_FEED: 'search'
}

export const mobileNumberRegex = /^\d{10}$/;
export const otpRegex = /^\d{6}$/;

export const saveData = async (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const removeData = async (key) => {
    localStorage.removeItem(key);
};

export const getQueryString = (finalQuery) => {
    let query = "";
    Object.keys(finalQuery).forEach((key, index) => {
        query += `${index === 0 ? "?" : "&"}${key}=${encodeURIComponent(finalQuery[key])}`;
    });
    return query;
}

export const getThumbnail = (index) => {
    if (index % 11 === 0) return IMAGES.frame1
    else if (index % 11 === 1) return IMAGES.frame2
    else if (index % 11 === 2) return IMAGES.frame3
    else if (index % 11 === 3) return IMAGES.frame4
    else if (index % 11 === 4) return IMAGES.frame5
    else if (index % 11 === 5) return IMAGES.frame6
    else if (index % 11 === 6) return IMAGES.frame7
    else if (index % 11 === 7) return IMAGES.frame8
    else if (index % 11 === 8) return IMAGES.frame9
    else if (index % 11 === 9) return IMAGES.frame10
    else if (index % 11 === 10) return IMAGES.frame11
}

export function applyHomeSearch(items, searchText) {
    const searchTokens = tokenize(searchText)

    const score1Fn = (item) => {
        const documentTokens = [item.speakerName, item.subTitle, item.brandName, item.title]
        const documentText = documentTokens.join(' ').toLowerCase()
        return scoreSearchTokens(documentText, searchTokens)
    }

    const score2Fn = (item) => {
        const synonyms = item.synonyms;
        const documentTokens = [item.speakerName, item.subTitle, item.brandName, item.topic, item.subTopic, ...(item?.subCategory || []), ...(item?.tags || []), ...(synonyms?.category || []), ...(synonyms?.function || []), ...(synonyms?.speakerRole || []), ...(synonyms?.subCategory || []), ...(synonyms?.subTopic || []), ...(synonyms?.tags || []), ...(synonyms?.tg || []), ...(synonyms?.topic || [])]
        const documentText = documentTokens.join(' ').toLowerCase()
        return scoreSearchTokens(documentText, searchTokens)
    }

    const resultItems = searchDocuments(items, score1Fn, score2Fn)
    return resultItems
}

export function tokenize(phrase) {
    const tokens = phrase.toLowerCase().trim().split(/ +/)
    return tokens
}

export function scoreSearchTokens(documentText, searchTokens) {
    const text = documentText.toLowerCase()
    let score = 0
    for (const token of searchTokens) {
        if (text.includes(token)) {
            score += 1
        }
    }

    return score == searchTokens.length ? 1 : 0
}

function searchDocuments(docs, score1Fn = () => { }, score2Fn = () => { }) {
    const scoredDocs = docs?.map((x) => {
        return {
            doc: x,
            score: score1Fn(x) * 100 + score2Fn(x)
        }
    })

    const sortedDocs = _.sort(scoredDocs, (x) => x.score, true)
    const filteredDocs = sortedDocs.filter((x) => x.score > 0)

    return filteredDocs.map((x) => x.doc)
}

export const calculateTimeFormat = (value) => {
    if (value > 3600) {
        const hours = Math.floor(value / 3600);
        const remainingMinutes = Math.floor((value % 3600) / 60);
        const remainingSeconds = value % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(remainingMinutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    else {
        const minutes = Math.floor(value / 60);
        const remainingSeconds = value % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }
}

export const whstappFeedbackNumber = '+919560988585';

export const whstappFeedbackDefaultMessage = `Hi, \nI've a suggestion for Hellomeets.\n...`;

export const softLoginCount = 3;

export const forceLoginCount = 3;

export const forceLoginTotalWatchTime = 180;

export const thresholdVideoTime = 10;

export function encodeFunction(text) {
    return btoa(text);
}

export function decodeFunction(encodedText) {
    return atob(encodedText);
}

export const extractUtmSource = (input) => {
    const startIndex = input.indexOf('=') + 1;
    const endIndex = input.indexOf('&') !== -1 ? input.indexOf('&') : input.length;
    return input.substring(startIndex, endIndex);
};

export const fetchMarketingUser = () => {
    return localStorage.getItem(Variables.marketingUser)
}

export function timeToSeconds(time) {
    const array = time.split(':').map(Number)
    if (array?.length == 2) return (array[0] * 60 + array[1])
    else return (array[0] * 3600 + array[1] * 60 + array[2])
}
