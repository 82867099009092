import { Variables } from "../../constants/variable";
import { DRAWER_OPEN, FORCE_LOGIN_POPUP, SOFT_LOGIN_POPUP, TOPIC_EXPANDED, USER_DATA, USER_INFO } from "../config/actionTypes";


export const getUserData = () => {
    return (dispatch) => {
        const res = localStorage.getItem(Variables.userData)
        dispatch(setUserData(JSON.parse(res)))
    };
};

export const setUserData = (payload) => {
    return { type: USER_DATA, payload: payload };
};

export const setUserInfo = (payload) => {
    return { type: USER_INFO, payload: payload };
};

export const setForceLoginPopup = (payload) => {
    return { type: FORCE_LOGIN_POPUP, payload: payload };
};

export const setSoftLoginPopup = (payload) => {
    return { type: SOFT_LOGIN_POPUP, payload: payload };
};

export const setDrawerOpen = (payload) => {
    return { type: DRAWER_OPEN, payload: payload };
};

export const setTopicExpanded = (payload) => {
    return { type: TOPIC_EXPANDED, payload: payload };
};

