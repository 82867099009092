import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import appReducer from "./reducer/rootReducer";
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, appReducer);

let appStore;
const setStore = (store) => {
  appStore = store;
}

export const getAppStore = () => {
  return appStore;
}

function configureStore() {
  let store = createStore(persistedReducer, compose(applyMiddleware(thunk)));
  let persistor = persistStore(store)
  setStore(store);

  return { store, persistor }
}

export default configureStore

