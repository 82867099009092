import { Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Layout from '../../component/Layout'
import './History.css'
import { COLORS } from '../../constants/colors';
import SearchBox from '../../component/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryData } from '../../redux/action/HistoryAction';
import { ENV, ROUTES, applyHomeSearch, fetchMarketingUser, getQueryString } from '../../constants/global';
import { useHistory } from 'react-router-dom';
import FeedVideosSkeleton from '../../component/Skeleton/FeedVideosSkeleton';
import VideoComponent from '../../component/VideoComponent';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { trackEvents } from '../../constants/analyticsService';

const HistoryScreen = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:767px)');

    const { userData, historyPageVideo, historyVideoLoading, drawerOpen } = useSelector((state) => state.common);
    const [searchText, setSearchText] = useState('')
    const [videoData, setVideoData] = useState([])

    useEffect(() => {
        setVideoData(historyPageVideo)
    }, [historyPageVideo])

    useEffect(() => {
        let finalQuery = {}
        finalQuery.userId = userData?.uid
        finalQuery.env = ENV
        dispatch(getHistoryData(getQueryString(finalQuery)))
    }, [])

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            setVideoData(applyHomeSearch(historyPageVideo, searchText))
            if (searchText != "") trackEvents.trackClickSearch({ text: searchText, source: 'History' })
        }, 500);

        return () => clearTimeout(debounceSearch);
    }, [searchText, historyPageVideo]);

    const onChangeSearchText = (text) => {
        setSearchText(text)
    }

    const ListItem = ({ item, index }) => {
        return (
            <div className='bottom-margin-60'>
                <VideoComponent
                    source={'History'}
                    displayProgressBar={true}
                    data={item}
                    index={index}
                    onClickVideo={() => history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'History' })}
                />
            </div>
        )
    }

    return (
        <>
            <Layout>
                <div className={`${isMobile ? (fetchMarketingUser() ? 'bottom-marketing-margin' : 'mobile-bottom-layout') : 'common-layout-margin'} top-margin-81`}>
                    <div className={`history-container ${isMobile && 'width-100-per'} ${drawerOpen ? 'width-200-px' : 'width-88-px'}`}>
                        <Typography variant='h4' className={`bottom-margin-15 bold-text ${isMobile && 'left-margin-20'}`} color={COLORS.BLUE}>
                            History
                        </Typography>
                        {/* <div className={isMobile && 'search-page-container-mobile'}>
                            <SearchBox
                                placeholder={'Search'}
                                onChange={(val) => onChangeSearchText(val)}
                                text={searchText}
                            />
                        </div> */}
                    </div>
                    <div className='bottom-margin-20'></div>
                    <div className={`width-feed-card top-margin-70`}>
                        {!userData ?
                            <div className='top-margin-150 history-signin-container'>
                                <HistoryIcon style={{ width: '60px', height: '60px' }} />
                                <Typography className='top-margin-10' variant='h5' color={COLORS.BLUE}>Keep track of what you watch</Typography>
                                <Typography className='top-margin-10' variant='body1' color={COLORS.BLUE}>Watch history isn't viewable when you're signed out.</Typography>
                                <div className='d-flex sign-in-container top-margin-10' onClick={() => history.push('/' + ROUTES.LOGIN)}>
                                    <AccountCircleIcon className='right-margin-10' />
                                    <Typography>Sign In</Typography>
                                </div>
                            </div>
                            :
                            <>
                                {historyVideoLoading ?
                                    <FeedVideosSkeleton />
                                    :
                                    <>
                                        {videoData?.length > 0 ?
                                            <>
                                                {videoData?.map((item, index) => {
                                                    return (
                                                        <ListItem
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                })
                                                }
                                            </>
                                            :
                                            <div className='top-margin-150 history-signin-container'>
                                                <HistoryIcon style={{ width: '70px', height: '70px' }} />
                                                <Typography className='top-margin-10' variant='h5' color={COLORS.BLUE}>No history found</Typography>
                                                <Typography className='top-margin-10' variant='body1' color={COLORS.BLUE}>Start watching videos</Typography>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default HistoryScreen

