import { LinearProgress, useMediaQuery } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES, calculateTimeFormat, encodeFunction } from '../constants/global';
import { IMAGES } from '../constants/images';
import LazyLoad from 'react-lazyload';
import { trackEvents } from '../constants/analyticsService';

const VideoComponent = ({ type, source, data, index, displayProgressBar, onClickVideo = () => { } }) => {
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:767px)');

    const isVideo = data?.entity != "Playlist"

    const { videoDuration, viewMeta } = isVideo ? data : data?.videos?.[0];
    const totalDuration = viewMeta?.totalDurationSeconds;
    const durationSeconds = viewMeta?.durationSeconds;

    const subTopicList = isVideo ? (data?.subTopic ? data?.subTopic?.split(',') : []) : data?.keywords
    const topicArray = [(isVideo ? data?.topic : data?.videos?.[0]?.topic), ...subTopicList]
    const finalTopicArray = []
    topicArray?.map((i) => {
        if (i) finalTopicArray.push(i)
    })
    const displayTopicArray = [...new Set(finalTopicArray)]?.slice(0, 4)

    const subTitle = isVideo ? data?.subTitle : data?.title
    const thumbnail = isVideo ? data?.thumbnail : data?.thumbnail
    const videoId = isVideo ? data?.id : data?.videos?.[0]?.id
    const speakerName = isVideo ? data?.speakerName : data?.videos?.[0]?.speakerName
    const brandName = isVideo ? data?.brandName : data?.videos?.[0]?.brandName
    const brandId = isVideo ? data?.brandId : data?.videos?.[0]?.brandId
    const speakerId = isVideo ? data?.speakerId : data?.videos?.[0]?.speakerId

    const handleSpeaker = () => {
        trackEvents.trackClickSpeakerLogo({
            speakerId: speakerId,
            videoId: videoId,
            speakerName: speakerName || '',
            source: source || ""
        })
        history.push('/' + ROUTES.SPEAKER_PROFILE + '/' + encodeFunction(speakerId) + '/' + speakerName?.split(' ')?.join('-')?.toLowerCase(), { source: source })
    }

    return (
        <>
            <div onClick={onClickVideo}>
                <div className={`thumbnail-container`}
                    style={{ borderRadius: isMobile ? '8px' : '12px' }}
                >
                    <LazyLoad once offset={800}>
                        <img
                            src={thumbnail || `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                            className='thumbnail-style'
                        />
                        <img
                            src={IMAGES.player_icon}
                            className={`${type == 'CONTINUE_WATCHING' ? 'width-50' : 'width-70'} play-icon-container`}
                        />
                    </LazyLoad>
                    <div className={`${displayProgressBar ? 'bottom-15' : 'bottom-4'} time-container`}>{calculateTimeFormat(videoDuration)}</div>
                    {displayProgressBar && <div>
                        <LinearProgress value={(durationSeconds * 100) / totalDuration} variant="determinate" className={`continue-watching-progress-bar ${isMobile ? 'width-80-per' : 'width-85-per'}`} />
                    </div>}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className={`feed-topic-container`}>
                        {displayTopicArray?.map((i, index) => {
                            return (
                                <div className='feed-topic-lable-view' key={index}>
                                    <label className='feed-topic-label'>{i}</label>
                                </div>
                            )
                        })}
                    </div>
                    {/* {data?.level && <div className='feed-topic-lable-view feed-topic-label'>{(data.level)}</div>} */}
                </div>
            </div>
            <div>
                <div className='feed-subtitle-style' onClick={onClickVideo}>{subTitle || ''}</div>
                {(!isVideo && data?.type == 'Full') ?
                    <></>
                    :
                    <div className='feed-speaker-info'>
                        <img src={IMAGES.feed_user} className='speaker-icon-style' />
                        <div onClick={handleSpeaker} className='feed-speaker-style'>&nbsp;&nbsp;{speakerName || ''},&nbsp;</div>
                        <div
                            onClick={() => {
                                trackEvents.trackClickBrandLogo({
                                    brandId: brandId,
                                    videoId: videoId,
                                    brandName: brandName || '',
                                    source: source || ""
                                })
                                history.push('/' + ROUTES.BRAND_PROFILE + '/' + encodeFunction(brandId) + '/' + brandName?.split(' ')?.join('-')?.toLowerCase(), { source: source || "" })
                            }}
                            className='feed-speaker-style'>{brandName || ''}</div>
                    </div>
                }
            </div>
        </>
    )
}
export default VideoComponent