import instance from "./apiConfig";

export const baseApiCall = (config) => {
    return new Promise((resolve, reject) => {
        instance(config)
            .then((response) => {
                resolve(response?.data);
            })
            .catch(async (e) => {
                console.log(e?.response, "error")
                try {
                    if (e?.response && e?.response?.data) reject(e.response.data)
                    else reject(e);
                }
                catch {
                    reject(e)
                }
            });
    });
};