import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './Home.css'
import Layout from '../../component/Layout'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { COLORS } from '../../constants/colors'
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { database } from '../../App'
import { child, get, push, ref, runTransaction, set } from 'firebase/database'
import { ENV, ROUTES, calculateTimeFormat, encodeFunction, fetchMarketingUser, forceLoginCount, forceLoginTotalWatchTime, getQueryString, thresholdVideoTime, timeToSeconds } from '../../constants/global'
import YouTube from 'react-youtube';
import { IMAGES } from '../../constants/images';
import VideoComponent from '../../component/VideoComponent';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import ArticleIcon from '@mui/icons-material/Article';
import { getPlayerFeedApiCall, getYoutubeDescriptionApiCall } from '../../redux/CommonService'
import { getHomePageVideo } from '../../redux/action/HomePageVideoAction'
import { Variables } from '../../constants/variable'
import Toast from '../../component/Toast'
import { toast } from 'react-toastify'
import { setDrawerOpen, setForceLoginPopup } from '../../redux/action/UserAction'
import ForceLogin from '../../component/ForceLogin'
import { trackEvents } from '../../constants/analyticsService'
import CloseIcon from '@mui/icons-material/Close';
import { useInView } from 'react-intersection-observer'
import LazyLoad from 'react-lazyload';

const VideoPlaying = () => {
    const isMobile = useMediaQuery('(max-width:767px)')
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()

    const { source, playlistId } = location.state ?? ''

    const { homePageVideo, userData, forceLoginPopup } = useSelector((state) => state.common);
    const isInternalUser = userData?.phoneNumber == "+911234567891"

    const [playerFeedData, setPlayerFeedData] = useState('')
    const [detail, setDetail] = useState(null)
    const [startTime, setStartTime] = useState(0)
    const [ticker, setTicker] = useState(0);
    const [isTicking, setIsTicking] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [hasCapturedView, setHasCapturedView] = useState(false);
    const [lastCaptureTs, setLastCaptureTs] = useState(0);
    const [openConnectPopup, setOpenConnectPopup] = useState(false);
    const [openSharePopup, setOpenSharePopup] = useState(false);
    const [liked, setLiked] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const [totalWatchTime, setTotalWatchTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false)
    const [displayTopicArray, setDisplayTopicArray] = useState([])
    const [timeStampArray, setTimeStampArray] = useState([])
    const [description, setDescription] = useState("")
    const [slotTime, setslotTime] = useState(0)
    const [playlistData, setPlaylistData] = useState('')

    const currentViewVideos = localStorage.getItem(Variables.viewVideo) || 0;
    const regex = /\/video\/([A-Za-z0-9_-]+)/;
    const match = location?.pathname?.match(regex);
    const videoId = match[1] || ""
    const playerRef = useRef(null);
    const leftDivRef = useRef(null);

    const tempId = localStorage.getItem(Variables.tempId)
    const params = new URLSearchParams(location.search);
    const dynamicLinkValue = params.get('dynamic_link')
    const dynamicLink = (isMobile && dynamicLinkValue) || false
    const showRelatedVideos = (!playlistData || playlistData?.videos?.length == 0) && !isMobile && playerFeedData

    useEffect(() => {
        dispatch(setDrawerOpen(false))
        if (dynamicLink) localStorage.setItem(Variables.marketingUser, true)
        const value = params.get('utm_source')
        if (value) {
            trackEvents.trackOpenVideoViaPost({
                videoId: location.pathname?.split('/')[2] || "",
                source: value
            })
        }
    }, [])

    useEffect(() => {
        if (playerRef?.current?.getCurrentTime()) {
            if (isPlaying) setTimeStampArray([...timeStampArray, { start: calculateTimeFormat(parseInt(playerRef?.current?.getCurrentTime())) }])
            else {
                setTimeStampArray([...timeStampArray, { end: calculateTimeFormat(parseInt(playerRef?.current?.getCurrentTime())), duration: slotTime, rate: playerRef?.current?.getPlaybackRate() }])
                setslotTime(0)
                localStorage.removeItem(Variables.videoSlotTime)
            }
        }
    }, [isPlaying, playerRef, videoId])

    useEffect(() => {
        let output = []
        timeStampArray?.map((obj, index) => {
            if (timeStampArray[index + 1]?.end) {
                output.push({
                    start: obj.start,
                    end: timeStampArray[index + 1].end,
                    duration: timeStampArray[index + 1].duration,
                    rate: timeStampArray[index + 1].rate
                })
            }
            else if (obj?.start) {
                output.push({
                    start: obj.start,
                })
            }
        });
        if (output?.length > 0) localStorage.setItem(Variables.videoTimeStamp, JSON.stringify(output))
    }, [timeStampArray])

    useEffect(() => {
        let timeInterval;

        if (isPlaying) {
            timeInterval = setInterval(() => {
                setTotalWatchTime((prevCounter) => prevCounter + 1);
                setslotTime((prevCounter) => prevCounter + 1)
            }, 1000);
        }

        return () => {
            clearInterval(timeInterval);
        }
    }, [isPlaying]);

    useEffect(() => {
        if (totalWatchTime >= 5 && !forceLoginPopup) {
            localStorage.setItem(Variables.totalWatchTime, totalWatchTime)
            localStorage.setItem(Variables.videoData, JSON.stringify(detail))
        }
    }, [totalWatchTime, forceLoginPopup])

    useEffect(() => {
        if (slotTime > 0 && !forceLoginPopup) localStorage.setItem(Variables.videoSlotTime, slotTime)
    }, [slotTime, forceLoginPopup])

    useEffect(() => {
        if (isMobile) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'hidden') removeTotalWatchTime();
                else {
                    setTotalWatchTime(0)
                    setTimeStampArray([])
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
        else {
            const handleUnload = () => {
                removeTotalWatchTime()
            };

            window.addEventListener('pagehide', handleUnload);

            return () => {
                window.removeEventListener('pagehide', handleUnload);
            };
        }
    }, [source, playlistId]);

    const removeTotalWatchTime = (s = source) => {
        const videoObject = JSON.parse(localStorage.getItem(Variables.videoData))
        if (videoObject) {
            const totalVideoTime = localStorage.getItem(Variables.totalWatchTime)
            const userTotalWatchTime = localStorage.getItem(Variables.userTotalWatchTime)
            const videoSlotTime = localStorage.getItem(Variables.videoSlotTime)

            if (userTotalWatchTime) localStorage.setItem(Variables.userTotalWatchTime, Number(userTotalWatchTime) + Number(totalVideoTime))
            else localStorage.setItem(Variables.userTotalWatchTime, totalVideoTime)


            const utm_value = params.get('utm_source')

            let timeStamp = JSON.parse(localStorage.getItem(Variables.videoTimeStamp))
            if (timeStamp && !timeStamp[timeStamp?.length - 1]?.end) {
                timeStamp[timeStamp.length - 1].end = calculateTimeFormat(parseInt(playerRef?.current?.getCurrentTime() || 0))
                timeStamp[timeStamp.length - 1].duration = JSON.parse(videoSlotTime)
                timeStamp[timeStamp.length - 1].rate = playerRef?.current?.getPlaybackRate()
            }
            trackEvents.trackViewVideo({
                videoId: videoObject?.id || "",
                subTitle: videoObject?.subTitle || "",
                durationSecondsUpdated: totalVideoTime,
                source: s || "Link",
                urlSource: utm_value || "",
                timeStamp: timeStamp || "",
                VideoSourceType: playlistId ? 'Mini Playlist' : 'Video',
                playlistId,
                videoDuration: calculateTimeFormat(videoObject?.videoDuration) || ""
            })

            trackViews()

            localStorage.removeItem(Variables.totalWatchTime)
            localStorage.removeItem(Variables.videoData)
            localStorage.removeItem(Variables.videoTimeStamp)
            localStorage.removeItem(Variables.videoSlotTime)
            setTimeStampArray([])
            setslotTime(0)
        }
    }

    const trackViews = async () => {
        try {
            if (userVideoViewsRef) {
                await runTransaction(userVideoViewsRef, (videoData) => {
                    const newVal = {
                        ...videoData,
                        ts: new Date().getTime(),
                        viewVideoCount: (videoData?.viewVideoCount || 0) + 1
                    }
                    return newVal
                });
            }
        }
        catch { }
    }

    const trackOpens = async () => {
        try {
            if (userVideoViewsRef) {
                await runTransaction(userVideoViewsRef, (videoData) => {
                    const newVal = {
                        ...videoData,
                        ts: new Date().getTime(),
                        openVideoCount: (videoData?.openVideoCount || 0) + 1
                    }
                    return newVal
                });
            }
        }
        catch { }
    }

    useEffect(() => {
        if (homePageVideo?.length == 0) checkTempId()
        else {
            const currentVideoData = homePageVideo?.filter(item => item?.id == videoId)
            if (!currentVideoData?.[0]) checkTempId()
        }
        CountViewVideo()
    }, [])

    const fetchDescription = async () => {
        try {
            const res = await getYoutubeDescriptionApiCall(videoId)
            setDescription(res?.items?.[0]?.snippet?.description || "")
        }
        catch { }
    }

    const checkTempId = async () => {
        const id = localStorage.getItem(Variables.tempId)
        if (!id) {
            const tempUserID = push(ref(database, '/tempusers')).key;
            const tempUserRef = ref(database, `/tempusers/${tempUserID}`);
            await set(tempUserRef, {
                name: '',
            });
            localStorage.setItem(Variables.tempId, tempUserID)
            fetchHomePageVideo(tempUserID)
        }
        else fetchHomePageVideo(id)
    }

    const fetchHomePageVideo = (tId) => {
        try {
            let finalQuery = {}
            finalQuery.env = ENV
            if (userData) finalQuery.userId = userData?.uid
            if (!userData && tId) {
                finalQuery.isTempUser = true
                finalQuery.userId = tId
            }

            dispatch(getHomePageVideo(getQueryString(finalQuery)))
        }
        catch {
            const utm_value = params.get('utm_source')

            trackEvents.trackErrorHomeFeedApi({
                videoId,
                userId: userData ? userData?.uid : tId,
                isTempUser: !userData,
                urlSource: utm_value || ""
            })
        }
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowRight':
                    if (playerRef.current) {
                        const currentTime = playerRef.current.getCurrentTime();
                        playerRef.current.seekTo(currentTime + 5, true);
                    }
                    break;
                case 'ArrowLeft':
                    if (playerRef.current) {
                        const currentTime = playerRef.current.getCurrentTime();
                        playerRef.current.seekTo(currentTime - 5, true);
                    }
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const videoData = homePageVideo?.filter(item => item?.id == videoId);
        const homeCount = parseInt(localStorage.getItem(Variables.scrollhomeVideoCount)) || 0
        const brandCount = parseInt(localStorage.getItem(Variables.scrollbrandVideoCount)) || 0
        const speakerCount = parseInt(localStorage.getItem(Variables.scrollspeakerVideoCount)) || 0
        const topicCount = parseInt(localStorage.getItem(Variables.scrollTopicVideoCount)) || 0
        const categoryCount = parseInt(localStorage.getItem(Variables.scrollCategoryVideoCount)) || 0
        const playerCount = parseInt(localStorage.getItem(Variables.scrollPlayerVideoCount)) || 0

        const countAvailable = source == 'Home' || source == 'Pills' || source == 'Brand Profile' || source == 'Speaker Profile' || source == 'Category' || source == 'Topic' || source == 'Related Videos'

        if (videoData?.[0]) {
            const utm_value = params.get('utm_source')
            setDetail(videoData?.[0] || "")

            trackEvents.trackOpenVideo({
                videoId,
                source: source ? source : 'Link',
                subTitle: videoData?.[0]?.subTitle || "",
                urlSource: utm_value || "",
                VideoSourceType: playlistId ? 'Mini Playlist' : 'Video',
                playlistId,
                scrollCount: countAvailable ? (homeCount + brandCount + speakerCount + topicCount + categoryCount + playerCount) : 0
            });
            if (countAvailable) {
                localStorage.removeItem(Variables.scrollhomeVideoCount)
                localStorage.removeItem(Variables.scrollbrandVideoCount)
                localStorage.removeItem(Variables.scrollspeakerVideoCount)
                localStorage.removeItem(Variables.scrollTopicVideoCount)
                localStorage.removeItem(Variables.scrollCategoryVideoCount)
                localStorage.removeItem(Variables.scrollPlayerVideoCount)
            }
            if (isInternalUser) {
                console.log({
                    title: videoData?.[0]?.title,
                    subTitle: videoData?.[0]?.subTitle,
                    thumbnail: videoData?.[0]?.thumbnail
                })
            }
        }
        fetchDescription()
    }, [homePageVideo, videoId])

    useEffect(() => {
        if (detail) {
            const subTopicList = detail?.subTopic ? detail?.subTopic?.split(',') : []
            const topicArray = [detail.topic, ...subTopicList]
            const finalTopicArray = []
            topicArray?.map((i) => {
                if (i) finalTopicArray.push(i)
            })
            setDisplayTopicArray([...new Set(finalTopicArray)]?.slice(0, 4))
        }
    }, [detail])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const viewedSeconds = useRef(0);
    const userRef = useMemo(() => {
        if (!userData?.uid && tempId) return ref(database, `/tempusers/${tempId}/videosV2/`)
        else if (userData?.uid) return ref(database, `/users/${userData?.uid}/videosV2/`)
        else return null
    }, [userData])

    const metaV2Ref = useMemo(() => {
        // if (!userData) return null
        return ref(database, `/videos/${detail?.id}/metaV2/`)
    }, [detail]);

    const userVideoOpensRef = useMemo(() => {
        if (!userRef || !detail) return null
        return child(userRef, `/opens/${detail?.id}`)
    }, [userRef, detail?.id]);

    const userVideoViewsRef = useMemo(() => {
        if (!userRef || !detail) return null
        return child(userRef, `/views/${detail?.id}`)
    }, [userRef, detail?.id]);

    const userVideoLikesRef = useMemo(() => {
        if (!userRef || !detail) return null
        return child(userRef, `/likes/${detail?.id}`)
    }, [userRef, detail?.id]);

    const videoLikesRef = useMemo(() => {
        // if (!userData) return null
        return child(metaV2Ref, `/likes`)
    }, [userRef, detail]);

    useEffect(() => {
        if (localStorage.getItem(Variables.totalWatchTime)) removeTotalWatchTime()
        return () => { removeTotalWatchTime() }
    }, [source, videoId, userVideoViewsRef, playlistId])

    useEffect(() => {
        trackOpens()
    }, [videoId, userVideoViewsRef])

    // Block for updating opens of video and adding video to users video list
    useEffect(() => {
        const f = async () => {
            // increment by 1
            if (metaV2Ref) {
                try {
                    await runTransaction(metaV2Ref, (videoData) => {
                        if (videoData !== null) {
                            const currentOpens = videoData?.opens || 0;
                            videoData.opens = currentOpens + 1;
                            return videoData;
                        }
                    });
                } catch (error) {
                    console.error('Error incrementing opens:', error);
                }
            }
            if (userVideoOpensRef) {
                try {
                    await runTransaction(userVideoOpensRef, (prev) => {
                        const newValue = {
                            ...prev,
                            ts: new Date().getTime()
                        }
                        return newValue
                    });
                } catch (error) {
                    console.error('Error in adding video:', error);
                }
            }
        }
        f()
    }, [detail?.id]);

    useEffect(() => {
        if (!userRef || (source != "History" && source != "Continue Watching")) return;

        const videoTimeRef = child(userRef, `/views/${detail?.id}`);

        get(videoTimeRef).then((snapshot) => {
            const data = snapshot.val();
            setStartTime(data?.durationSeconds || 0);
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    }, [userRef, detail, source]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getRelatedVideos()
    }, [detail, videoId])

    const CountViewVideo = () => {
        if (!userData) {
            const userTotalWatchTime = localStorage.getItem(Variables.userTotalWatchTime)

            if (currentViewVideos >= forceLoginCount && userTotalWatchTime >= forceLoginTotalWatchTime) dispatch(setForceLoginPopup(true))
            else localStorage.setItem(Variables.viewVideo, parseInt(currentViewVideos) + 1);
        }
        else dispatch(setForceLoginPopup(false))
    }

    // Block for tracking video duration
    const onReady = (event) => {
        playerRef.current = event.target;
        event.target.playVideo();
        const player = event.target;
        setDuration(player.getDuration());
    };

    const onPlay = (event) => {
        const player = event.target;
        setInterval(() => {
            setCurrentTime(player.getCurrentTime());
        }, 1000);
    };

    const onEnd = () => {
        setTotalWatchTime(0)
        const currentVideoIndex = playlistData?.videoIdList?.indexOf(videoId)
        if (currentVideoIndex >= 0 && currentVideoIndex != playlistData?.videoIdList?.length - 1) {
            history.push('/' + ROUTES.VIDEO_PLAYING + '/' + playlistData?.videoIdList[currentVideoIndex + 1], { source: 'End Video', playlistId: playlistData?.id })
            setDetail(playlistData?.videos[currentVideoIndex + 1]);
        }
        else {
            if (playerFeedData?.videos?.[0]?.entity == 'Playlist') {
                history.push('/' + ROUTES.VIDEO_PLAYING + '/' + playerFeedData?.videos?.[0]?.videoIdList?.[0], { source: 'End Video', playlistId: playerFeedData?.videos?.[0]?.id })
                setDetail(playerFeedData?.videos?.[0]?.videos?.[0])
            }
            else {
                history.push('/' + ROUTES.VIDEO_PLAYING + '/' + playerFeedData?.videos?.[0]?.id, { source: 'End Video' })
                setDetail(playerFeedData?.videos?.[0]);
            }
        }
        CountViewVideo()
        setTimeStampArray([])
    }

    const onPause = () => {
        clearInterval(currentTime);
    };

    const onStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING && !forceLoginPopup) setIsPlaying(true)
        else setIsPlaying(false)
    }

    const onPlaybackRateChange = (event) => {
        trackEvents.trackVideoPlayBackRate({
            rate: event.data,
            videoId,
            subTitle: detail?.subTitle || "",
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTicking) {
                return
            }
            setTicker((ticker) => ticker + 1)
        }, 1000);
        return () => clearInterval(interval)
    }, [detail?.id])

    useEffect(() => {
        const unmount = async () => {
            if (viewedSeconds.current < 3) {
                return
            }
        }

        setHasCapturedView(false);

        return () => {
            unmount()
        };
    }, [detail?.id]);

    useEffect(() => {
        const updateDuration = async () => {
            // if (userData == null ) return null
            const newDurationSeconds = Math.ceil(currentTime);
            const totalDurationSeconds = Math.ceil(duration);

            viewedSeconds.current = newDurationSeconds


            if (newDurationSeconds < 3) {
                return
            }

            // capture user view event
            if (!hasCapturedView && metaV2Ref) {
                setHasCapturedView(true);
                // increment by 1
                try {
                    metaV2Ref && await runTransaction(metaV2Ref, (videoData) => {
                        let vData = videoData
                        if (vData?.views) vData.views += 1;
                        return vData;
                    });
                } catch (error) {
                    console.error('Error incrementing video view:', error);
                }
            }

            if ((newDurationSeconds >= totalDurationSeconds)) {
                setIsTicking(false)
            }

            const diffLastCapture = new Date().getTime() - lastCaptureTs
            if (diffLastCapture < thresholdVideoTime * 1000) {
                return
            }

            setLastCaptureTs(new Date().getTime())

            try {
                userVideoViewsRef && await runTransaction(userVideoViewsRef, (videoData) => {
                    const maxDurationViewed = newDurationSeconds
                    const maxCompletionStatus = maxDurationViewed >= totalDurationSeconds

                    const newVal = {
                        ...videoData,
                        ts: new Date().getTime(),
                        isCompleted: maxCompletionStatus,
                        durationSeconds: maxDurationViewed,
                        totalDurationSeconds: totalDurationSeconds,
                    }

                    return newVal
                });
            } catch (error) {
                console.error('Error updating user video view:', error);
            }
        }
        updateDuration()
    }, [metaV2Ref, userRef, ticker, detail])

    const fetchVideoLike = () => {
        if (!userVideoLikesRef) return null
        get(userVideoLikesRef).then((snapshot) => {
            const prevVal = snapshot.val();
            const hasLiked = prevVal?.isLiked || false;
            setLiked(hasLiked);
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    }

    useEffect(() => {
        if (userRef) fetchVideoLike();
    }, [metaV2Ref, userRef, detail])

    const getRelatedVideos = async () => {
        if (detail) {
            try {
                let finalQuery = {}
                finalQuery.env = ENV
                finalQuery.videoId = videoId;

                if (userData) finalQuery.userId = userData?.uid
                if (!userData && tempId) {
                    finalQuery.isTempUser = true
                    finalQuery.userId = tempId
                }
                if (playlistId) finalQuery.playlistId = playlistId

                let feedData = await getPlayerFeedApiCall(getQueryString(finalQuery));
                setPlayerFeedData(feedData);
                if (feedData?.currentVideo?.miniPlaylist) setPlaylistData(feedData?.currentVideo?.miniPlaylist)
                else if (feedData?.currentPlaylist) setPlaylistData(feedData?.currentPlaylist)
                else setPlaylistData("")
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    const _setLiked = async (type) => {
        if (type == 'DISLIKE') {
            trackEvents.trackLikeVideo({
                videoId,
                source: source ? source : 'Link',
                subTitle: detail?.subTitle || ""
            })
        }
        else {
            trackEvents.trackDisLikeVideo({
                videoId,
                source: source ? source : 'Link',
                subTitle: detail?.subTitle || ""
            })
        }

        let hasLiked;
        get(userVideoLikesRef).then((snapshot) => {
            const prevVal = snapshot.val();
            hasLiked = prevVal?.isLiked || false

        }).catch((error) => {
            console.error("Error fetching data:", error);
        });

        try {
            await runTransaction(videoLikesRef, (prev) => {
                const oldValue = parseInt(prev) || 0
                let newValue = hasLiked ? oldValue - 1 : oldValue + 1
                return newValue;
            });

            await runTransaction(userVideoLikesRef, (prev) => {
                const newIsLiked = !hasLiked
                let newValue = {
                    ts: new Date().getTime(),
                    isLiked: newIsLiked
                }
                return newValue;
            });
        } catch (error) {
            console.error('Error incrementing video like:', error);
        }
        finally {
            fetchVideoLike()
        }
    }

    const handleLinkedInShare = () => {
        trackEvents.trackLInkedinShareVideo({ videoId, subTitle: detail?.subTitle || "" });
        window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href + "?utm_source=videosharing_linkedin")}&mini=true`, '_blank');
    };

    const downloadFile = async (url) => {
        const fileNameSegments = url.split('/')

        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileNameSegments[fileNameSegments.length - 1] || "Sample.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleSpeaker = () => {
        trackEvents.trackClickSpeakerLogo({
            speakerId: detail?.speakerId,
            videoId: detail?.id,
            speakerName: detail?.speakerName || '',
            source: 'Video'
        })
        history.push('/' + ROUTES.SPEAKER_PROFILE + '/' + encodeFunction(detail?.speakerId) + '/' + detail?.speakerName?.split(' ')?.join('-')?.toLowerCase(), { source: 'Video' })
    }

    const handleBrand = () => {
        trackEvents.trackClickBrandLogo({
            brandId: detail?.brandId,
            videoId: videoId,
            brandName: detail?.brandName || '',
            source: 'Video'
        })
        history.push('/' + ROUTES.BRAND_PROFILE + '/' + encodeFunction(detail?.brandId) + '/' + detail?.brandName?.split(' ')?.join('-')?.toLowerCase(), { source: 'Video' })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const playerWidth = window.innerWidth - 470

    const ListItem = ({ item, index }) => {
        const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true
        });

        if (item?.isDependent) return <></>

        if (inView) localStorage.setItem(Variables.scrollPlayerVideoCount, index + 1)
        return (
            <div ref={ref} key={index} className='bottom-margin-60'>
                <VideoComponent
                    source={'Related Videos'}
                    index={index}
                    data={item}
                    onClickVideo={() => {
                        setTotalWatchTime(0)
                        CountViewVideo()
                        setStartTime(0)
                        setPlaylistData("")
                        if (item?.entity == 'Playlist') history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Related Videos', playlistId: item?.id })
                        else history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Related Videos' })
                        setDetail(item)
                        setTimeStampArray([])
                        setIsPlaying(!isPlaying)
                    }}
                />
            </div>
        )
    }

    const PlayerView = useCallback(({ videoId, detail, startTime, onEnd, onReady, onPlay, onPause, onStateChange, ref, onPlaybackRateChange, playerWidth, isMobile }) => {
        return (
            <YouTube
                title={detail?.id}
                videoId={videoId}
                opts={{
                    height: isMobile ? '250px' : `${playerWidth / 1.777}px`,
                    width: isMobile ? '100%' : `${playerWidth}px`,
                    playerVars: {
                        autoplay: 1,
                        start: startTime,
                        rel: 0,
                    },
                }}
                onReady={onReady}
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
                onStateChange={onStateChange}
                ref={ref}
                onPlaybackRateChange={onPlaybackRateChange}
            />
        )
    }, [detail])
    return (
        <>
            <Toast />
            <Layout>
                <div className={`${!isMobile ? 'layout-video-playing' : (fetchMarketingUser() ? 'top-padding-80 bottom-marketing-margin' : 'top-padding-80 mobile-bottom-layout')} `}>
                    <div className={`video-playing-container video-playing-main-block ${!isMobile && 'rounded-corner'}`}>
                        <div>
                            {forceLoginPopup ?
                                <img
                                    alt='thumnail'
                                    src={detail?.thumbnail}
                                    style={{
                                        height: isMobile ? '250px' : `${playerWidth / 1.777}px`,
                                        width: isMobile ? '100%' : `${playerWidth}px`,
                                        borderRadius: isMobile ? '0px' : '12px'
                                    }}
                                />
                                :
                                <PlayerView
                                    videoId={videoId}
                                    detail={detail}
                                    startTime={startTime}
                                    onReady={onReady}
                                    onPlay={onPlay}
                                    onPause={onPause}
                                    onEnd={onEnd}
                                    onStateChange={onStateChange}
                                    ref={playerRef}
                                    onPlaybackRateChange={onPlaybackRateChange}
                                    playerWidth={playerWidth}
                                    isMobile={isMobile}
                                />
                            }
                            <div ref={leftDivRef} style={{ width: isMobile ? '100%' : `${playerWidth}px` }}>
                                <Typography className={`${isMobile && 'right-margin-20 left-margin-20'} bottom-margin-5 ${"bold-text fs-1-2-rem"}`} color={COLORS.BLUE}>
                                    {detail?.subTitle || ''}
                                </Typography>
                                <div
                                    style={{ flexWrap: 'wrap' }}
                                    className={`row-flex-style bottom-margin-20 ${isMobile && 'right-margin-20 left-margin-20'}`}>
                                    {displayTopicArray?.map((i, index) => {
                                        return (
                                            <div className='row-flex-style' key={index}>
                                                <label className='video-playing-topic-label'>{i}</label>
                                                {index != displayTopicArray?.length - 1 && <div className='feed-topic-divider'></div>}
                                            </div>
                                        )
                                    })}
                                </div>
                                <>
                                    <div className={`align-space-block ${isMobile && 'left-margin-20 right-margin-20'}`}>
                                        <div className='row-flex-style'>
                                            <img
                                                onClick={handleSpeaker}
                                                src={detail?.speakerPhoto}
                                                alt={'Speaker Photo'}
                                                className='video-playing-speaker-photo'
                                            />
                                            <div>
                                                <div
                                                    onClick={handleSpeaker}
                                                    className='video-playing-speaker-name'>{detail?.speakerName || ''}</div>
                                                <div className='video-playing-speaker-role'>
                                                    <div onClick={handleSpeaker}>{detail?.speakerRole},&nbsp;</div>
                                                    <div onClick={handleBrand}>{detail?.brandName || ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {(detail?.speakerLinkedInId == '' && detail?.speakerTwitterId == '') ?
                                            <></>
                                            :
                                            <div
                                                className='video-playing-connect-view'
                                                onClick={() => {
                                                    if (detail?.speakerLinkedInId && detail?.speakerTwitterId) {
                                                        setOpenConnectPopup(true)
                                                        trackEvents.trackConnectViaPopup({ speakerName: detail?.speakerName, videoId, subTitle: detail?.subTitle })
                                                    }
                                                    else {
                                                        if (detail?.speakerLinkedInId) {
                                                            window.open(detail?.speakerLinkedInId, '_blank')
                                                            trackEvents.trackLinkedinLinkClick({ name: detail?.speakerName, link: detail?.speakerLinkedInId, source: 'Connect Via Popup' })
                                                        } else if (detail?.speakerTwitterId) {
                                                            window.open(detail?.speakerTwitterId, '_blank')
                                                            trackEvents.trackTwitterLinkClick({ name: detail?.speakerName, link: detail?.speakerTwitterId, source: 'Connect Via Popup' })
                                                        }
                                                    }
                                                }}>
                                                <img
                                                    src={IMAGES.person_add}
                                                    alt={'person icon'}
                                                />
                                                <div>{isMobile ? 'Connect' : `Connect with ${detail?.speakerName?.split(' ')[0] || ""}`}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className={`align-space-block top-margin-15 bottom-margin-15 ${isMobile && 'left-margin-20 right-margin-20'}`}>
                                        <div className='video-playing-share-container'>
                                            <Tooltip title="Like">
                                                {liked ?
                                                    <div
                                                        onClick={() => _setLiked('LIKE')}
                                                        className='video-playing-share-icon-view'>
                                                        <img
                                                            src={IMAGES.thumbs_up_empty}
                                                            alt={'thumb icon'}
                                                        />
                                                    </div>
                                                    :
                                                    <div
                                                        onClick={() => _setLiked('DISLIKE')}
                                                        className='video-playing-share-icon-view'>
                                                        <img
                                                            src={IMAGES.thumbs_up_green}
                                                            alt={'thumb icon'}
                                                        />
                                                    </div>
                                                }
                                            </Tooltip>
                                            <Tooltip title="Linkedin Share">
                                                <div
                                                    onClick={handleLinkedInShare}
                                                    className='video-playing-share-icon-view'>
                                                    <img
                                                        src={IMAGES.linkedin_blue}
                                                        alt={'linkedin icon'}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Share">
                                                <div
                                                    onClick={() => {
                                                        setOpenSharePopup(true);
                                                        trackEvents.trackShareVideoPopup({ videoId, subTitle: detail?.subTitle })
                                                    }}
                                                    className='video-playing-share-icon-view'>
                                                    <img
                                                        src={IMAGES.share_blue}
                                                        alt={'linkedin icon'}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {!isMobile &&
                                            <div className='row-flex-style'>
                                                <div
                                                    className='whatsapp-commnunity-container'
                                                    onClick={() => {
                                                        trackEvents.trackWhatsappCommunityButton()
                                                        window.open('https://chat.whatsapp.com/KdUHgYOn23g22kKVnEQ0Zz', "_blank")
                                                    }}>
                                                    <img
                                                        src={IMAGES.whatsapp_icon}
                                                        alt={'Whatsapp Icon'}
                                                        className='whatsapp-icon-style'
                                                    />
                                                    <div>Network with 400+ PMs!</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {isMobile &&
                                        <div className='whatsapp-gray-container bottom-margin-20 d-flex'>
                                            <img
                                                src={IMAGES.whatsapp_icon}
                                                alt={'Whatsapp Icon'}
                                                className='whatsapp-icon-style'
                                            />
                                            <div className='left-margin-15'>
                                                <div className='join-pm-title'>Network with 400+ PMs!</div>
                                                <div className='join-whatsapp-desc'>Network with product leaders and peers, access exclusive content, and engage in dynamic discussions</div>
                                                <div className='join-now-button-main-container'>
                                                    <div
                                                        onClick={() => {
                                                            trackEvents.trackWhatsappCommunityButton()
                                                            window.open('https://chat.whatsapp.com/KdUHgYOn23g22kKVnEQ0Zz', "_blank")
                                                        }}
                                                        className='join-now-group-button'>JOIN NOW</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {description &&
                                        <div className='downloads-container'>
                                            <div>
                                                <div>
                                                    <Typography className='fs-16 bold-text' color={COLORS.BLACK}>{`Description`}</Typography>
                                                </div>
                                                <div>
                                                    {description?.split("\n")?.slice(0, showMore ? 100000 : 1)?.map((item, index) => {
                                                        const timeDescription = item?.match(/^\d{1,2}:\d{2}(:\d{2})?/)
                                                        const firstText = timeDescription?.[0] || ""
                                                        const secondText = item?.replace(/(\d{1,2}:\d{2}(:\d{2})?)\s/g, '')
                                                        const parts = secondText.split(/(https?:\/\/\S+)/)

                                                        return (
                                                            <div
                                                                className={`summary-style ${(item?.toLowerCase()?.includes('timestamp') || item?.toLowerCase()?.includes('timestamps') || item?.toLowerCase()?.includes('resources:')) && 'top-margin-10'}`}
                                                                key={index}
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        if (firstText) {
                                                                            trackEvents.trackClickDescriptionTimeStamp({
                                                                                timestamp: firstText,
                                                                                videoId,
                                                                                subTitle: detail?.subTitle
                                                                            })
                                                                            playerRef.current.seekTo(timeToSeconds(firstText), true)
                                                                            window.scrollTo({
                                                                                top: 0,
                                                                                left: 0,
                                                                                behavior: 'smooth',
                                                                            });
                                                                        }
                                                                    }}
                                                                    className='timestamp-style'>
                                                                    {firstText}{" "}
                                                                </span>
                                                                {parts?.map((part) => {
                                                                    if (part?.startsWith('http')) {
                                                                        return (
                                                                            <span
                                                                                onClick={() => {
                                                                                    trackEvents.trackClickDescriptionLink({
                                                                                        link: part,
                                                                                        videoId,
                                                                                        subTitle: detail?.subTitle
                                                                                    })
                                                                                    if (part?.includes('hellomeets')) window.open(part, "_self")
                                                                                    else window.open(part, "_blank")
                                                                                }}
                                                                                className='timestamp-style'>{part}</span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>{part}</span>
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                        )
                                                    })}
                                                    <label className="show-more-btn" onClick={() => setShowMore(!showMore)}>
                                                        {showMore ? 'Show Less' : '...more'}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            </div>
                        </div>
                        {(playlistData || showRelatedVideos) &&
                            <div
                                style={{ maxHeight: isMobile ? null : `${leftDivRef?.current?.offsetHeight + 562.5}px` }}
                                className='video-part-main-container'>
                                <div className='part-video-main-title'>{playlistData?.title || "Related videos"}</div>
                                {(showRelatedVideos ? playerFeedData?.videos?.slice(0, 5) : playlistData?.videos)?.map((item, index) => {
                                    const selectedVideo = videoId == item?.id
                                    return (
                                        <div
                                            onClick={() => {
                                                setTotalWatchTime(0)
                                                CountViewVideo()
                                                setStartTime(0)
                                                if (showRelatedVideos) {
                                                    if (item?.entity == 'Playlist') history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Player Side Videos', playlistId: item?.id })
                                                    else history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Player Side Videos' })
                                                }
                                                else history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Mini Playlist Parts', playlistId: playlistData?.id })
                                                setDetail(item)
                                                setTimeStampArray([])
                                                setIsPlaying(!isPlaying)
                                            }}
                                            key={index} className={`part-main-block ${selectedVideo && `selected-part-style`}`}>
                                            <div className='row-flex-style'>
                                                {!showRelatedVideos &&
                                                    <>
                                                        {selectedVideo ?
                                                            <div style={{ width: '15px' }}>
                                                                <img
                                                                    src={IMAGES.play_icon}
                                                                    alt={'Play Icon'}
                                                                    className='play-icon-style'
                                                                />
                                                            </div>
                                                            :
                                                            <div className='video-count-style'>{index + 1}</div>
                                                        }
                                                    </>
                                                }
                                                <div className='video-playing-lazyload'>
                                                    <LazyLoad once offset={800}>
                                                        <img
                                                            src={item?.thumbnail || ``}
                                                            className='part-thumbnail-style'
                                                        />
                                                        <div className={'video-playing-time-container'}>{calculateTimeFormat(item?.videoDuration || item?.videos?.[0]?.videoDuration)}</div>
                                                    </LazyLoad>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='part-subtitle-style'>{item?.subTitle || item?.title || ""}</div>
                                                {(item?.entity == 'Playlist' && item?.type == 'Full') ?
                                                    <></>
                                                    :
                                                    <Typography variant='body2' color={COLORS.GRAY} className='cursor-pointer' style={{ fontSize: '0.805rem' }}>
                                                        {item?.speakerName || item?.videos?.[0]?.speakerName || ""}
                                                    </Typography>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className='home-title-style'>Personalised feed for you ⚡️</div>
                    <div className={`width-feed-card`}>
                        {(showRelatedVideos ? playerFeedData?.videos?.slice(5) : playerFeedData?.videos)?.map((item, index) => {
                            return (
                                <ListItem
                                    item={item}
                                    index={index}
                                />
                            )
                        })}
                    </div>
                </div>
            </Layout >
            <Dialog
                open={openConnectPopup}
                onClose={() => setOpenConnectPopup(false)}
            >
                <div className='align-space-block'>
                    <DialogTitle>
                        {"Connect via"}
                    </DialogTitle>
                    <CloseIcon
                        onClick={() => setOpenConnectPopup(false)}
                        className='right-margin-15 cursor-pointer'
                    />
                </div>
                <DialogContent>
                    <DialogContentText style={{ width: '300px' }}>
                        {detail?.speakerTwitterId && detail?.speakerTwitterId != "" &&
                            <div
                                onClick={() => {
                                    window.open(detail?.speakerTwitterId, '_blank')
                                    trackEvents.trackTwitterLinkClick({ name: detail?.speakerName, link: detail?.speakerTwitterId, source: 'Connect Via Popup' })
                                }}
                                className='d-flex bottom-margin-15 cursor-pointer twitter-dialog-box'>
                                <div className='social-media-logo'>
                                    <img src={IMAGES.twitter_logo} style={{ width: '30px' }} />
                                </div>
                                <div>
                                    <Typography variant='subtitle1' className='bold-text' color={COLORS.BLUE}>Twitter</Typography>
                                </div>
                            </div>}
                        {detail?.speakerLinkedInId && detail?.speakerLinkedInId != "" &&
                            <div
                                onClick={() => {
                                    window.open(detail?.speakerLinkedInId, '_blank')
                                    trackEvents.trackLinkedinLinkClick({ name: detail?.speakerName, link: detail?.speakerLinkedInId, source: 'Connect Via Popup' })
                                }}
                                className='d-flex bottom-margin-15 cursor-pointer twitter-dialog-box'>
                                <div className='social-media-logo'>
                                    <img src={IMAGES.linkedin_logo} style={{ width: '30px' }} />
                                </div>
                                <div>
                                    <Typography variant='subtitle1' className='bold-text' color={COLORS.BLUE}>LinkedIn</Typography>
                                </div>
                            </div>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSharePopup}
                onClose={() => setOpenSharePopup(false)}
            >
                <div className='align-space-block'>
                    <DialogTitle>
                        {"Share"}
                    </DialogTitle>
                    <CloseIcon
                        onClick={() => setOpenSharePopup(false)}
                        className='right-margin-15 cursor-pointer'
                    />
                </div>
                <DialogContent>
                    <DialogContentText>
                        <div className='share-icon-button-group'>
                            <div className='bottom-margin-10'>
                                <WhatsappShareButton onClick={() => trackEvents.trackShareVideo({ videoId, subTitle: detail?.subTitle || "", source: 'Whatsapp' })} url={window.location.href + '?utm_source=videosharing_whatsapp'} className='right-margin-15'>
                                    <WhatsappIcon size={50} round />
                                </WhatsappShareButton>
                                <TwitterShareButton onClick={() => trackEvents.trackShareVideo({ videoId, subTitle: detail?.subTitle || "", source: 'Twitter' })} url={window.location.href + '?utm_source=videosharing_twitter'} className='right-margin-15'>
                                    <TwitterIcon size={50} round />
                                </TwitterShareButton>
                                <FacebookShareButton onClick={() => trackEvents.trackShareVideo({ videoId, subTitle: detail?.subTitle || "", source: 'Facebook' })} url={window.location.href + '?utm_source=videosharing_facebook'}>
                                    <FacebookIcon size={50} round />
                                </FacebookShareButton>
                            </div>
                        </div>
                        <div className='link-container top-margin-35'>
                            <div className='left-margin-10 right-margin-45'>
                                {(window.location.href)?.substring(0, isMobile ? 15 : 30) + '...' || ''}
                            </div>
                            <div className='cursor-pointer right-padding-15' onClick={() => {
                                navigator.clipboard.writeText(window.location.href);
                                toast.success('Link Copied.', {
                                    autoClose: 2000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                trackEvents.trackCopyLink({ videoId, subTitle: detail?.subTitle })
                            }
                            }>
                                <div className='copy-button'>Copy</div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <ForceLogin videoId={videoId} />
        </>
    )
}

export default VideoPlaying

