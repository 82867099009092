import React, { useEffect, useState } from 'react'
import '../Home/Home.css'
import Layout from '../../component/Layout'
import { useLocation, useHistory } from 'react-router-dom';
import { ENV, ROUTES, applyHomeSearch, fetchMarketingUser, getQueryString } from '../../constants/global';
import { useMediaQuery, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, setDrawerOpen } from '../../redux/action/UserAction';
import { Variables } from '../../constants/variable';
import { getHomePageVideo } from '../../redux/action/HomePageVideoAction';
import { push, ref, set } from 'firebase/database';
import { database } from '../../App';
import { getSearchData } from '../../redux/action/SearchAction';
import { IMAGES } from '../../constants/images';
import VideoComponent from '../../component/VideoComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import { COLORS } from '../../constants/colors';
import { trackEvents } from '../../constants/analyticsService';

const SearchFeed = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const isMobile = useMediaQuery('(max-width:767px)');

    const { text } = location.state ?? ''
    const { homePageVideo, userData, searchPageData, playlists } = useSelector((state) => state.common);
    const tempId = localStorage.getItem(Variables.tempId)

    const [searchText, setSearchText] = useState(text || "")
    const [filterdVideos, setFilteredVideos] = useState([]);
    const [topicList, setTopicList] = useState([])
    const [viewAllTopic, setViewAllTopic] = useState(false)
    const [filterdPlaylist, setFilteredPlaylist] = useState([]);

    useEffect(() => {
        if (isMobile) history.push('/')
    }, [isMobile])

    useEffect(() => {
        setFilteredVideos(applyHomeSearch(homePageVideo, searchText))
        setFilteredPlaylist(applyHomeSearch(playlists, searchText))
        setTopicList(searchPageData?.topicList?.filter(topic => topic?.value?.name?.toLowerCase().includes(searchText?.toLowerCase()?.trim())))
    }, [homePageVideo, playlists, searchPageData]);

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            setFilteredVideos(applyHomeSearch(homePageVideo, searchText))
            setFilteredPlaylist(applyHomeSearch(playlists, searchText))
            setTopicList(searchPageData?.topicList?.filter(topic => topic?.value?.name?.toLowerCase().includes(searchText?.toLowerCase()?.trim())))
            if (searchText != "") trackEvents.trackClickSearch({ text: searchText, source: 'Search' })
        }, 500);

        return () => clearTimeout(debounceSearch);
    }, [searchText]);

    useEffect(() => {
        dispatch(setDrawerOpen(true))
        dispatch(getUserData())
        const res = localStorage.getItem(Variables.userData)
        if (!res) tempFunction()
        else {
            getPageData()
            getSearchDataFunc()
        }
    }, [])

    const tempFunction = async () => {
        const id = localStorage.getItem(Variables.tempId)
        if (!id) {
            const tempUserID = push(ref(database, '/tempusers')).key;
            const tempUserRef = ref(database, `/tempusers/${tempUserID}`);
            await set(tempUserRef, {
                name: '',
            });
            localStorage.setItem(Variables.tempId, tempUserID)
            getPageData(tempUserID)
            getSearchDataFunc(tempUserID)
        }
        else {
            getPageData(id)
            getSearchDataFunc(id)
        }
    }

    const getPageData = (tId = tempId) => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tId
        }
        dispatch(getHomePageVideo(getQueryString(finalQuery)))
    }

    const getSearchDataFunc = (tId = tempId) => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tId
        }
        dispatch(getSearchData(getQueryString(finalQuery)))
    }

    const handleTopic = (item) => {
        history.push('/' + ROUTES.SEARCH_RESULT + '/topic/' + item.value.name, { source: 'Search' })
    }

    return (
        <>
            <Layout searchText={searchText} setSearchText={setSearchText}>
                <div className={`${isMobile ? (fetchMarketingUser() ? 'bottom-marketing-margin' : 'mobile-bottom-layout') : 'common-layout-margin'}`}>
                    <div className={'top-margin-110'}>
                        {filterdVideos?.length > 0 && <div className='home-title-style'>Showing {filterdVideos?.length} Results for “{searchText}”</div>}
                        {topicList?.length > 0 &&
                            <>
                                <div className='align-space-block top-margin-40 bottom-margin-30'>
                                    <div style={{ marginBottom: '0px', marginTop: '0px' }} className='home-title-style'>Results from Topics</div>
                                    {topicList?.length > 6 &&
                                        <div
                                            onClick={() => setViewAllTopic(!viewAllTopic)}
                                            className={`view-all-style`}>
                                            <div className='view-all-text'>{viewAllTopic ? 'View Less' : 'View All'}</div>
                                            <img
                                                src={viewAllTopic ? IMAGES.chevron_up : IMAGES.chevron_down}
                                                alt={'down-icon'}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='home-topic-container'>
                                    {topicList?.slice(0, viewAllTopic ? 100 : 6)?.map((item, index) => {
                                        return (
                                            <div key={index} className={`home-topic-block`} onClick={() => handleTopic(item)}>
                                                <div className='product-topic-view'>
                                                    <img src={item?.value?.logo} />
                                                </div>
                                                <div className='home-topic-text'>{item?.value?.name || ""}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                    {filterdPlaylist?.length > 0 &&
                        <div className='playlist-background-style'>
                            <div className='home-title-style'>Results from Playlists</div>
                            <div className={`video-list-container width-feed-card`}>
                                {filterdPlaylist?.map((item, index) => {
                                    return (
                                        <div key={index} className='bottom-margin-60'>
                                            <VideoComponent
                                                source={'Playlists'}
                                                data={item}
                                                index={index}
                                                onClickVideo={() => {
                                                    history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Playlists', playlistId: item?.id })
                                                }}
                                            />
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    <div>
                        <div className='home-title-style top-margin-50'>Results from Videos</div>
                        <div className={`video-list-container width-feed-card`}>
                            {filterdVideos?.length > 0 ?
                                <>
                                    {filterdVideos?.map((item, index) => {
                                        return (
                                            <div className='bottom-margin-60'>
                                                <VideoComponent
                                                    source={'Search'}
                                                    data={item}
                                                    index={index}
                                                    onClickVideo={() => {
                                                        if (item?.entity == 'Playlist') history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Search', playlistId: item?.id })
                                                        else history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Search' })
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </>
                                :
                                <div className='top-margin-85 history-signin-container'>
                                    <RefreshIcon style={{ width: '60px', height: '60px' }} />
                                    <Typography className='top-margin-10' variant='h5' color={COLORS.BLUE}>No results found</Typography>
                                    <Typography className='top-margin-10' variant='body1' color={COLORS.BLUE}>Try something else</Typography>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    )
}

export default SearchFeed

