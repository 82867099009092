import React from 'react'
import './Login.css'
import { IMAGES } from '../../constants/images'

const PrivacyPolicy = () => {

    return (
        <div className='policy-container'>
            <img
                alt={'Hellomeets-logo'}
                src={IMAGES.hellomeets_logo}
            />
            <h1 className='top-margin-25'>Privacy Policy</h1>

            <h3 className='top-margin-20'>1. OVERVIEW</h3>
            <p>At Hello Meets Technology Pvt. Ltd. (the “Company”, “we” or “us”), we use our website and other internet-based tools for the following purpose:</p>
            <p>HelloMeets is a learning app where we help our users learn from the top executives of startups via bite-sized video subscriptions & events.</p>
            <p>We are required to collect and retain information about the people that access and use the website/app (“Users” or “you”). This Policy applies to our privacy practices on all communications between the Company and Users, including but not limited to communication via:</p>
            <p>the Company website (including the website hosted at <a href='https://about-us.hellomeets.com/' target="_blank">www.hellomeets.com</a> and any other web domain used by the Company to communicate with users now or in the future); And all other services provided by the Company, as described on the Website. This Privacy Policy (“Policy”) sets out how we use, protect, collect and preserve information submitted by Users. This Policy should be read in conjunction with any Terms of Use or policies available on the Website.</p>
            <p>By accessing or submitting any information through the Website, you are agreeing to the terms of this Policy. You are prohibited from accessing or using the Website unless you fully understand and agree to this Policy and all Terms of Use available for review on the Website.</p>
            <p>If you have any questions about this Policy, please contact us by sending an email with your questions to systems@hellomeets.com before accessing or entering any information on the Website.</p>
            <p>It is very important that you read and fully understand these Terms of Use before using the Website. By agreeing to these Terms of Use and using the Website/app, you are entering into a legally binding contract which will impact your rights.</p>
            <p>By accessing any part of the Website, you agree to be bound by these Terms of Use. You are also confirming that you have read and fully understood these Terms of Use. If you do not agree to or understand all of these Terms of Use, then you may not access the Website or use any services available on the Website/app.</p>
            <p>These Terms of Use should be read in conjunction with the Privacy Policy and any other rules, guidelines, or policies posted on the Website.</p>


            <h3 className='top-margin-30'>2. INFORMATION ABOUT USERS WE COLLECT AND STORE</h3>
            <p>When you choose to sign up on the app, we will collect and retain all personal identifying information submitted to us.</p>
            <p>We will take steps to protect the information submitted to us by Users and we will not disclose any personal identifying information without the consent of Users, unless otherwise set out in this Policy.</p>
            <p>Personal identifying information may include but is not necessarily limited to:</p>
            <p>Email, username, and phone number, which must be collected from each user. Full name, gender, professional title, image, age & bio, which are optional & may be collected if the user provides that information in future.</p>
            <p>We will not use personal identifying information to contact you for any reason unrelated to your use of the Website or for commercial reasons unrelated to the business of the Company. Except with your consent or where required by law, we will not provide personal identifying information to any third party.</p>


            <h3 className='top-margin-30'>3. UPLOADING PERSONAL INFORMATION</h3>
            <p>In order to facilitate their interactions on the Website, Users are able, where they deem appropriate, to upload information including personal identifying information. Users should only upload information to the extent that such information enhances their experience of using the Website.</p>

            <h3 className='top-margin-30'>4. PERMITTED USES OF DATA</h3>
            <p>We use data collected from the Website for various purposes, including to: provide the Website to you and accompanying customer support; notify you about updates and changes to the Website; preventing and addressing technical issues with the Website; monitor usage of the Website.</p>
            <p>Without restriction, we may use any information you submit through the Website for any purpose we deem appropriate so long as it is anonymous and stripped of any and all personal identifying information.</p>
            <p>We may use the data collected from the Website, devices, browsers, and any other sources, for its own purposes, both related to and unrelated to improving the Website.</p>

            <h3 className='top-margin-30'>5. LEGAL BASIS FOR PROCESSING PERSONAL IDENTIFYING INFORMATION</h3>
            <p>We may process your personal identifying information for a number of reasons, including:</p>
            <p>performing a contract with you relating to the Website;</p>
            <p>you have provided us consent to do so;</p>
            <p>fulfilling a legal obligation;</p>
            <p>fulfilling our legitimate interests, which are not overridden by your rights;</p>
            <p>for payment processing purposes.</p>

            <h3 className='top-margin-30'>6. ACCESSING, CORRECTING & DELETING YOUR PERSONAL INFORMATION</h3>
            <p>If you wish to access or amend your personal information or to request that we permanently delete your personal information from our servers, please send an email to systems@hellomeets.com. We will ensure your personal information is accessible, amended and/or deleted in a reasonable time after receiving that request. Deleting such information may require us to terminate any account you may have created in its entirety and prevent you from using the Website.</p>
            <p>So long as your account remains active, we will preserve your personal information in accordance with the Policy.</p>
            <p>You may decline to share certain personal information with us, in which case you may not be able to use the Website, either in its entirety or certain features.</p>
            <p>If you have not accessed your account for a total of 3 years, we may terminate your account, without notice, along with any personal information retained by us.</p>
            <p>When deleting your information, whether by request or due to inactivity, we will use standard electronic means to remove your personal information from our files.</p>
            <p>We will also retain any and all information that we are required to retain under any applicable laws for the full duration of time required under those laws.</p>


            <h3 className='top-margin-30'>7. KEEPING YOUR DATA SECURE</h3>
            <p>We will store your information in electronic format on industry-standard servers that we have selected. Except where required to do so by law, we will not store your personal information in any physical or paper-based format.</p>

            <h3 className='top-margin-30'>8. THIRD-PARTY APPLICATIONS</h3>
            <p>To provide an enhanced experience to Users, including to track conversions and analytics, we may engage services and features controlled by third-parties. We cannot control or dictate how any third-party application you use in conjunction with the Website, now or at any point in the future, will treat any personal information you transmit through their servers when using the Website. We advise you to contact these parties directly to review their privacy policies.</p>
            <p>Without limitation, the Company uses or may use the following third-party applications and services in conjunction with the Website: Google Analytics.</p>
            <p>By using any of these third-party applications to interact with the Website, we will not be given access to your login credentials, user name, password, payment information, or any information you may provide directly to these applications.</p>

            <h3 className='top-margin-30'>9. USE OF WEBSITE BY MINORS</h3>
            <p>The Website is not intended to be accessed by persons under the age of 18. The Company does not engage in any marketing efforts aimed at children or minors.</p>
            <p>Persons under the age of 18 are prohibited from using the Website without the express permission and direct supervision of a parent or guardian. Minors are prohibited from submitting any personal identifying information through the Website. The Company will immediately delete any personal identifying information submitted in violation of this Policy.</p>


            <h3 className='top-margin-30'>10. DISCLOSURE OF PERSONAL INFORMATION</h3>
            <p>In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
            <p>We may disclose your personal information to third parties:</p>
            <p>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets. If the Company or its assets are acquired by a third party, in which case personal data about Users may be one of the transferred assets. If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms of Use; or to protect the rights, property, or safety of the Company, our Users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>

            <h3 className='top-margin-30'>11. AMENDING THIS POLICY</h3>
            <p>This Policy may be updated and amended from time to time. We reserve the right to change this Policy at any time, and any amended Policy is effective upon posting to the Website. We will make efforts to communicate any changes to this Policy we deem material, in our sole discretion, via email or notifications on the Website. Your continued use of the Website will be deemed acceptance of any amended Policy.</p>

            <h3 className='top-margin-30'>12. CONTACT US</h3>
            <p>We encourage you to send us questions and inquiries on this Policy and the steps we take to keep your personal information secure. Please send us an email: systems@hellomeets.com</p>
            <p>We will respond to all inquiries on the Policy within 7 days.</p>
            <p>The above waiver shall not apply to claims or disputes arising under consumer protection legislation or any other claim or dispute where a waiver of class action lawsuits is unenforceable at law.</p>
        </div>
    )
}

export default PrivacyPolicy