export const COLORS = {
    WHITE: '#FFFFFF',
    RED: '#DD1F1B',
    BLUE: '#183B56',
    ORANGE: '#E5931C',
    GRAY: '#5A7184',
    LIGHTBLUE: '#295DCD',
    BLACK: '#000000',
    RANDOM_COLORS: [
        "#9DBBFF",
        "#FA9E28",
        "#187432",
        "#FC4755",
        "#7563E7",
        "#E15C94"
    ]
}