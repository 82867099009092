import { getThumbnail } from "../../constants/global";
import { getHomePageVideoApiCall } from "../CommonService";
import { CONTINUE_WATCHING, HOME_PAGE_VIDEO, HOME_VIDEO_LOADING, PILLS_DATA, PLAYLISTS, RECOMMENDATION, TRENDING_VIDEO } from "../config/actionTypes";

export const getHomePageVideo = (query) => {
    return (dispatch) => {
        dispatch(setHomeVideoLoading(true))
        getHomePageVideoApiCall(query)
            .then((res) => {
                let finalPillsData = [{ type: 'ALL', value: 'ALL' }, ...res?.pills]

                dispatch(setHomePageVideo(res?.videos))
                dispatch(setPillsData(finalPillsData))
                dispatch(setTrendingVideos(res?.trendingVideos))
                dispatch(setContinueWathcingVideos(res?.continueWatching))
                dispatch(setPlaylistsVideos(res?.playlists?.filter(i => i.type == 'Full')))
                dispatch(setHomeVideoLoading(false))
                dispatch(setRecommendation(Object.entries(res?.mostRecentOpenMap)?.length > 0))
            })
            .catch(() => {
                dispatch(setHomeVideoLoading(false))
            })

    };
};

export const setHomePageVideo = (payload) => {
    return { type: HOME_PAGE_VIDEO, payload: payload };
};

export const setHomeVideoLoading = (payload) => {
    return { type: HOME_VIDEO_LOADING, payload: payload };
};

export const setPillsData = (payload) => {
    return { type: PILLS_DATA, payload: payload };
};

export const setTrendingVideos = (payload) => {
    return { type: TRENDING_VIDEO, payload: payload };
};

export const setContinueWathcingVideos = (payload) => {
    return { type: CONTINUE_WATCHING, payload: payload };
};

export const setRecommendation = (payload) => {
    return { type: RECOMMENDATION, payload: payload };
};

export const setPlaylistsVideos = (payload) => {
    return { type: PLAYLISTS, payload: payload };
};

