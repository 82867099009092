import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IMAGES } from '../constants/images';
import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import './Component.css'
import { COLORS } from '../constants/colors';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, encodeFunction, whstappFeedbackDefaultMessage, whstappFeedbackNumber } from '../constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { get, ref } from 'firebase/database';
import { database } from '../App';
import { setUserInfo } from '../redux/action/UserAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { MainEventTypes, trackEvents } from '../constants/analyticsService';
import { Variables } from '../constants/variable';

export default function LayoutAppBar({ onclick, searchText, setSearchText }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const divRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:767px)');
    const [openInstallPopup, setOpenInstallPopup] = useState(false);

    const { userData, userInfo, searchPageData } = useSelector((state) => state.common);
    const [tempSearch, setTempSearch] = useState("")
    const [serachPopup, setSearchPopup] = useState(false)

    const oldSearchHistory = JSON.parse(localStorage.getItem(Variables.searchHistory)) || []

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            // Perform your action here
            setSearchPopup(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (searchText) setTempSearch(searchText)
    }, [searchText])

    const userRef = useMemo(() => {
        if (!userData?.uid) return null;
        return ref(database, `/users/${userData?.uid}`)
    }, [])

    const getUserInfo = () => {
        get(userRef).then((snapshot) => {
            const data = snapshot.val();
            dispatch(setUserInfo(data));
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    };

    useEffect(() => {
        if (userRef) getUserInfo();
    }, [userRef]);

    return (
        <>
            <div className='app-bar-style'>
                <div className='align-space-block' style={{ margin: '0px 15px', width: '100%' }}>
                    <div className='row-flex-style'>
                        {!isMobile &&
                            <IconButton onClick={onclick} className='right-margin-10'>
                                <MenuIcon />
                            </IconButton>
                        }
                        <img
                            onClick={() => history.push('/')}
                            src={IMAGES.hellomeets_logo}
                            className='logo-style'
                            alt={'Hellomeets-logo'}
                        />
                    </div>
                    {!isMobile &&
                        <div className='header-search-bar-style'>
                            <img
                                src={IMAGES.search_icon}
                                alt={'search icon'}
                                className='search-icon-style'
                            />
                            <input
                                value={tempSearch}
                                className='header-search-input'
                                type="text"
                                onChange={(e) => {
                                    if (e.target.value == "" && location.pathname == `/` + ROUTES.SEARCH_FEED) history.goBack()
                                    setSearchText(e.target.value)
                                    setTempSearch(e.target.value)
                                }}
                                placeholder="Search any product topic"
                                onKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        let newArray = []
                                        newArray = [tempSearch, ...oldSearchHistory]
                                        localStorage.setItem(Variables.searchHistory, JSON.stringify([...new Set(newArray)]))
                                        history.push('/' + ROUTES.SEARCH_FEED, { text: tempSearch })
                                    }
                                }}
                                onFocus={() => setSearchPopup(true)}
                            />
                            {tempSearch &&
                                <CloseIcon
                                    onClick={() => {
                                        setSearchText("")
                                        setTempSearch("")
                                        if (location.pathname == `/` + ROUTES.SEARCH_FEED) history.goBack()
                                    }}
                                    style={{ color: 'gray' }}
                                    className='cursor-pointer'
                                />
                            }
                        </div>
                    }
                    <div className='row-flex-style'>
                        <>
                            <div className={`install-app-container cursor-pointer`} onClick={() => {
                                setOpenInstallPopup(true)
                                trackEvents.trackInstallApp(MainEventTypes.INSTALL_APP)
                            }}>
                                <ArrowCircleDownIcon className='feedback-icon-style' />
                                <div className='install-app-text'>Install app</div>
                            </div>
                        </>
                        {userInfo == '' ?
                            <div className='d-flex sign-in-container' onClick={() => history.push('/' + ROUTES.LOGIN)}>
                                <AccountCircleIcon className='right-margin-10 width-20' />
                                <Typography variant='body2'>Sign In</Typography>
                            </div>
                            :
                            <IconButton onClick={() => {
                                history.push('/' + ROUTES.PROFILE);
                                trackEvents.trackProfileOpen({ name: userInfo?.name || "" });
                            }}>
                                {userInfo?.name ?
                                    <Avatar className='width-35 height-35' style={{ backgroundColor: COLORS.ORANGE }}>{userInfo?.name?.charAt(0)}</Avatar>
                                    :
                                    <Avatar className='width-35 height-35' alt="Remy Sharp" src={IMAGES.avatar} />
                                }
                            </IconButton>
                        }
                    </div>
                </div>
            </div>
            <Dialog
                open={openInstallPopup}
                onClose={() => setOpenInstallPopup(false)}
            >
                <div className='align-space-block'>
                    <DialogTitle className='install-title'>
                        {"Install Hellomeets"}
                    </DialogTitle>
                    <CloseIcon
                        onClick={() => setOpenInstallPopup(false)}
                        className='right-margin-15 cursor-pointer'
                    />
                </div>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            {isMobile ?
                                <div className='install-container top-margin-20'>
                                    <div className='row-flex-style store-container width-100-per bottom-margin-20' onClick={() => {
                                        trackEvents.trackInstallAppAppStoreClick({ source: 'Header Popup' })
                                        window.open("https://apps.apple.com/vn/app/hellomeets-fintech-case-study/id1668866466", "_blank")
                                    }}>
                                        <div className='row-flex-style'>
                                            <img src={IMAGES.apple_store} className='store-logo' />
                                        </div>
                                        <div className='left-margin-10'>
                                            <div className='fs-11 lh-7'>Download on the</div>
                                            <div className='store-name'>App Store</div>
                                        </div>
                                    </div>
                                    <div className='row-flex-style store-container width-100-per' onClick={() => {
                                        trackEvents.trackInstallAppPlayStoreClick({ source: 'Header Popup' })
                                        window.open("https://play.google.com/store/apps/details?id=com.hellomeets.app.shorts", "_blank")
                                    }}>
                                        <div className='row-flex-style'>
                                            <img src={IMAGES.google_play_store} className='store-logo' />
                                        </div>
                                        <div className='left-margin-10'>
                                            <div className='fs-11 lh-7'>Download on the</div>
                                            <div className='store-name'>Google Play</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='install-container-web top-margin-20'>
                                    <div className='right-padding-30' style={{ borderRight: '1px solid #d3d3d3' }}>
                                        <div className='row-flex-style store-container bottom-margin-20' onClick={() => window.open("https://apps.apple.com/vn/app/hellomeets-fintech-case-study/id1668866466", "_blank")}>
                                            <div className='row-flex-style'>
                                                <img src={IMAGES.apple_store} className='store-logo' />
                                            </div>
                                            <div className='left-margin-10'>
                                                <div className='fs-11 lh-7'>Download on the</div>
                                                <div className='store-name'>App Store</div>
                                            </div>
                                        </div>
                                        <img src={IMAGES.appstore_qr_code} className='width-100-per' />
                                    </div>
                                    <div className='left-padding-30'>
                                        <div className='row-flex-style store-container bottom-margin-20' onClick={() => window.open("https://play.google.com/store/apps/details?id=com.hellomeets.app.shorts", "_blank")}>
                                            <div className='row-flex-style'>
                                                <img src={IMAGES.google_play_store} className='store-logo' />
                                            </div>
                                            <div className='left-margin-10'>
                                                <div className='fs-11 lh-7'>Download on the</div>
                                                <div className='store-name'>Google Play</div>
                                            </div>
                                        </div>
                                        <img src={IMAGES.playstore_qr_code} className='width-100-per' />
                                    </div>
                                </div>}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog >
            {serachPopup &&
                <div ref={divRef} className='search-popup-style'>
                    {oldSearchHistory?.length > 0 &&
                        <>
                            <div className='search-popup-title-text'>Search History</div>
                            <div className='search-history-main-container'>
                                {oldSearchHistory?.map((item, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setSearchText(item)
                                                setTempSearch(item)
                                                history.push('/' + ROUTES.SEARCH_FEED, { text: item })
                                            }}
                                            className='search-popup-history-container' key={index}>
                                            <div className='search-popup-history-text'>{item}</div>
                                            <img
                                                src={IMAGES.arrow_top_up}
                                                alt={'arrow'}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='search-popup-divider'></div>
                        </>
                    }
                    <div className='search-popup-title-text'>Top Categories</div>
                    <div className='search-popup-category-list'>
                        {searchPageData?.topicList?.slice(0, 5)?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        setSearchPopup(false)
                                        history.push('/' + ROUTES.SEARCH_RESULT + '/topic/' + item.value.name, { source: 'Search Popup' })
                                    }}
                                    key={index} className='search-popup-category-view'>{item?.value?.name}</div>
                            )
                        })}
                    </div>
                    <div className='search-popup-title-text'>Top Companies</div>
                    <div className='search-popup-category-list'>
                        {searchPageData?.trendingBrands?.slice(0, 5)?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        setSearchPopup(false)
                                        trackEvents.trackClickBrandLogo({
                                            brandId: item?.id,
                                            brandName: item?.name || '',
                                            source: 'Search Popup'
                                        })
                                        history.push('/' + ROUTES.BRAND_PROFILE + '/' + encodeFunction(item?.id) + '/' + item?.name?.split(' ')?.join('-')?.toLowerCase(), { source: 'Search Popup' })
                                    }}
                                    key={index} className='search-popup-category-view'>{item?.name}</div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    );
}
