import { Dialog, DialogContent, DialogContentText, Stack, Typography, useMediaQuery, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../constants/colors";
import './Component.css';
import { IMAGES } from "../constants/images";
import { useEffect, useState } from "react";
import { getSearchData } from "../redux/action/SearchAction";
import { ENV, getQueryString } from "../constants/global";
import { Variables } from "../constants/variable";
import CompanySkeleton from "./Skeleton/CompanySkeleton";
import { trackEvents } from "../constants/analyticsService";
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { database } from "../App";
import { ref, update } from "firebase/database";

const options = [
    'Student/Intern', 'APM', 'PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product', 'Others'
];

const OnboardingModal = ({ onboardingModal, setOnboardingModal, onChangeTopic }) => {
    const { searchPageData, userData, searchPageDataLoading, userInfo } = useSelector((state) => state.common);
    const isMobile = useMediaQuery('(max-width:767px)')
    const dispatch = useDispatch()
    const tempId = localStorage.getItem(Variables.tempId)
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")
    const [activeIndex, setActiveIndex] = useState(0)
    const [role, setRole] = useState("")
    const [roleError, setRoleError] = useState("")
    const [otherRole, setOtherRole] = useState('');
    const [otherRoleError, setOtherRoleError] = useState(false)
    const [topicList, setTopicList] = useState([])

    useEffect(() => {
        const value = params.get('utm_source')
        trackEvents.trackOnboardingPopup({ source: value || "" })
        getSearchDataFunc()
    }, [])

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false)
                onSelectTopic(selectedItem)
            }, 2000)
        }
    }, [loading])

    useEffect(() => {
        const userRole = localStorage.getItem(Variables.localRole)
        if (userRole) {
            setActiveIndex(1)
            fetchList(userRole)
        }
        if (userInfo) {
            setActiveIndex(1)
            fetchList(userInfo?.role)
        }
    }, [searchPageData, userInfo])

    const getSearchDataFunc = () => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tempId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tempId
        }
        dispatch(getSearchData(getQueryString(finalQuery)))
    }

    const onSelectTopic = async (item) => {
        try {
            let userRef = null
            if (userData) userRef = ref(database, `/users/${userData?.uid}`)
            else {
                const tempId = localStorage.getItem(Variables.tempId)
                userRef = ref(database, `/tempusers/${tempId}`)
            }
            await update(userRef, { topic: item.value.name });
        }
        catch { }

        trackEvents.trackOnboardingPopupTopic({ topic: item.value.name })
        onChangeTopic(item.value.name)
        localStorage.setItem(Variables.onboardingModal, true)
        setOnboardingModal(false)
    }

    const handleOtherRole = (event) => {
        const inputUsername = event.target.value;
        if (!inputUsername) {
            setOtherRoleError(true);
        } else {
            setOtherRoleError(false);
        }

        setOtherRole(inputUsername);
    };

    const fetchList = (userRole = role) => {
        const seniorRole = ['PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product']
        if (seniorRole?.includes(userRole)) setTopicList(searchPageData?.topicList)
        else {
            const sortOrder = ["Fundamentals", "Design", "Tech", "Analytics", "User Research",];

            const data = searchPageData?.topicList ? [...searchPageData.topicList] : []
            data?.sort((a, b) => {
                const aName = a.value.name;
                const bName = b.value.name;

                const aIndex = sortOrder.indexOf(aName);
                const bIndex = sortOrder.indexOf(bName);

                if (aIndex !== -1 && bIndex !== -1) {
                    return aIndex - bIndex;
                } else if (aIndex !== -1) {
                    return -1;
                } else if (bIndex !== -1) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setTopicList(data)
        }
    }

    const onClickContinue = async () => {
        if (!role) return setRoleError(true)
        if (role == 'Others' && !otherRole) return setOtherRoleError(true)
        localStorage.setItem(Variables.localRole, role != 'Others' ? role : otherRole)
        trackEvents.trackOnboardingPopupRole({ role: role != 'Others' ? role : otherRole })
        try {
            let userRef = null
            if (userData) userRef = ref(database, `/users/${userData?.uid}`)
            else {
                const tempId = localStorage.getItem(Variables.tempId)
                userRef = ref(database, `/tempusers/${tempId}`)
            }
            await update(userRef, { role: role != 'Others' ? role : otherRole });
        }
        catch { }
        setActiveIndex(1)
        fetchList()
    }

    if (searchPageDataLoading) return null

    return (
        <Dialog
            open={onboardingModal}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: isMobile ? '100%' : "fit-content",
                        maxWidth: isMobile ? '100%' : "fit-content",
                        borderRadius: '14px',
                        maxHeight: isMobile ? '75%' : '100%',
                    },
                },
            }}
        >
            <DialogContent className="onboarding-modal-style">
                {loading ?
                    <DialogContentText className="loading-onboarding-container">
                        <img
                            src={IMAGES.loader_icon}
                            alt={'loader'}
                            className="loader-style"
                        />
                        <div className="onboarding-red-title">Crafting your personalized feed…</div>
                    </DialogContentText>
                    :
                    <DialogContentText
                        className="dialog-inner-container"
                    >
                        <img
                            src={IMAGES.hellomeets_logo}
                            alt={'logo'}
                            className="onboarding-logo-style"
                        />
                        <div className="onborading-header-title">Upskilling platform for PMs</div>
                        <div className="onboarding-divider-style"></div>
                        <div className="onboarding-subtitle-style">{activeIndex == 0 ? "Select your current role:" : "Select topics to learn"}</div>
                        {activeIndex == 0 ?
                            <div className="onboarding-dropdown-container">
                                <Dropdown
                                    options={options}
                                    placeholder="Select your role"
                                    onChange={(item) => {
                                        setRole(item.value)
                                        setRoleError(false)
                                    }}
                                    menuClassName="my-dropdown-menu"
                                />
                                {roleError &&
                                    <div className="role-error-style">{'Please select your role'}</div>
                                }
                                {role == 'Others' &&
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Enter your role"
                                        variant="outlined"
                                        className='bottom-margin-20'
                                        value={otherRole}
                                        onChange={handleOtherRole}
                                        error={otherRoleError}
                                        helperText={otherRoleError ? 'Please enter your role' : ''}
                                    />
                                }
                            </div>
                            :
                            <div className="onboarding-topic-list">
                                {topicList?.slice(0, 8)?.map((item, index) => {
                                    const activeTopic = item?.value?.name == selectedItem?.value?.name
                                    return (
                                        <div
                                            onClick={() => setSelectedItem(item)}
                                            className={`onboarding-topic-pills ${activeTopic && "active-onboarding-topic-pills"}`}
                                            key={index}>
                                            {item?.value?.name || ""}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        <div className="justify-center">
                            {activeIndex == 0 ?
                                <div
                                    onClick={() => onClickContinue()}
                                    className="onboarding-continue-button">Continue</div>
                                :
                                <div
                                    style={{ backgroundColor: selectedItem ? '#0B66C2' : '#717A8A' }}
                                    onClick={() => selectedItem ? setLoading(true) : {}}
                                    className="onboarding-continue-button">Continue</div>
                            }
                        </div>
                    </DialogContentText>
                }
            </DialogContent>
        </Dialog >
    )
}

export default OnboardingModal;