import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';

const ContinueWatchingVideosSkeleton = () => {
    const isMobile = useMediaQuery('(max-width:767px)');

    return (
        <Grid container spacing={3}>
            {Array(3).fill()?.map((_, index) => {
                return (
                    <Grid
                        key={index}
                        item xs={12} sm={4}>
                        <Skeleton
                            variant="rectangular"
                            className='thumbnail-container bottom-margin-10'
                            style={{ borderRadius: isMobile ? '0px' : '8px' }}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default ContinueWatchingVideosSkeleton