import { getThumbnail } from "../../constants/global";
import { getSearchFeedApiCall } from "../CommonService";
import { SEARCH_PAGE_DATA, SEARCH_PAGE_DATA_LOADING } from "../config/actionTypes";

export const getSearchData = (query) => {
    return (dispatch) => {
        dispatch(setSearchPageDataLoading(true))
        getSearchFeedApiCall(query)
            .then((res) => {
                let newArray = []
                let newTopicsArray = []
                let newSubCategoryArray = []
                let newBrandArray = []
                let newSpeakerArray = []
                res?.videos?.map((item, index) => {
                    if (item?.id) {
                        newArray.push({
                            ...item,
                            // 'thumbnail': getThumbnail(index)
                        })
                    }
                })
                res?.topicList?.map((item, index) => {
                    const topicVideos = res.videos?.filter(i => i?.topic?.includes(item?.name));
                    if (topicVideos?.length > 0) {
                        newTopicsArray.push({
                            value: item
                        })
                    }
                })
                res?.subCategoryList?.map((item, index) => {
                    const categoryVideos = res.videos?.filter(i => i?.subCategory?.includes(item?.name));
                    if (categoryVideos?.length > 0) {
                        newSubCategoryArray.push({
                            value: item
                        })
                    }
                })
                res?.trendingBrands?.map((item, index) => {
                    const brandVideos = res.videos?.filter(i => i?.brandId == item?.id);
                    if (brandVideos?.length > 0) {
                        newBrandArray.push(item)
                    }
                })
                res?.trendingSpeakers?.map((item, index) => {
                    const speakerVideos = res?.videos?.filter(i => i?.speakerId == item?.id);
                    if (speakerVideos?.length > 0) {
                        newSpeakerArray.push(item)
                    }
                })
                res['videos'] = newArray;
                res['topicList'] = newTopicsArray;
                res['subCategoryList'] = newSubCategoryArray;
                res['trendingBrands'] = newBrandArray
                res['trendingSpeakers'] = newSpeakerArray
                dispatch(setSearchData(res))
                dispatch(setSearchPageDataLoading(false))
            })
            .catch(() => {
                dispatch(setSearchData([]))
                dispatch(setSearchPageDataLoading(false))
            })
    };
};

export const setSearchData = (payload) => {
    return { type: SEARCH_PAGE_DATA, payload: payload };
};

export const setSearchPageDataLoading = (payload) => {
    return { type: SEARCH_PAGE_DATA_LOADING, payload: payload };
};

