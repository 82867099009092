export const USER_DATA = "USER_DATA";
export const USER_INFO = "USER_INFO";
export const HOME_PAGE_VIDEO = "HOME_PAGE_VIDEO";
export const PILLS_DATA = "PILLS_DATA";
export const TRENDING_VIDEO = "TRENDING_VIDEO";
export const CONTINUE_WATCHING = "CONTINUE_WATCHING";
export const HISTORY_PAGE_VIDEO = "HISTORY_PAGE_VIDEO";
export const HOME_VIDEO_LOADING = "HOME_VIDEO_LOADING";
export const HISTORY_VIDEO_LOADING = "HISTORY_VIDEO_LOADING";
export const USER_LOGGING_OUT = "USER_LOGGING_OUT";
export const SEARCH_PAGE_DATA = "SEARCH_PAGE_DATA";
export const SEARCH_PAGE_DATA_LOADING = "SEARCH_PAGE_DATA_LOADING";
export const FORCE_LOGIN_POPUP = "FORCE_LOGIN_POPUP"
export const SOFT_LOGIN_POPUP = "SOFT_LOGIN_POPUP"
export const DRAWER_OPEN = "DRAWER_OPEN"
export const RECOMMENDATION = "RECOMMENDATION"
export const PLAYLISTS = "PLAYLISTS"
export const TOPIC_EXPANDED = "TOPIC_EXPANDED"

