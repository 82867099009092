import React, { useEffect, useState } from 'react'
import './Profile.css'
import Layout from '../../component/Layout'
import { useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import VideoComponent from '../../component/VideoComponent';
import { ENV, ROUTES, decodeFunction, fetchMarketingUser, getQueryString } from '../../constants/global';
import FeedVideosSkeleton from '../../component/Skeleton/FeedVideosSkeleton';
import { getBrandProfileApiCall } from '../../redux/CommonService';
import { trackEvents } from '../../constants/analyticsService';
import { useInView } from 'react-intersection-observer'
import { Variables } from '../../constants/variable'
import { IMAGES } from '../../constants/images';

const BrandProfile = () => {
    const isMobile = useMediaQuery('(max-width:767px)');

    const [brandData, setBrandData] = useState(null);
    const [brandVideosData, setBrandVideosData] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const location = useLocation();
    const history = useHistory();
    const { source } = location.state ?? "";

    const brandId = decodeFunction(location?.pathname?.split("/")?.[2]);

    useEffect(() => {
        if (!brandId) return history.push('/')
        getBrandProfile();
        window.scrollTo(0, 0);
    }, [])

    const getBrandProfile = async () => {
        setIsLoading(true)
        let finalQuery = {}
        finalQuery.env = ENV
        finalQuery.brandId = brandId
        try {
            const brandData = await getBrandProfileApiCall(getQueryString(finalQuery))
            setBrandData(brandData?.profile)
            trackEvents.trackBrandProfile({
                brandId,
                brandName: brandData?.profile?.name,
                source: source || "Link"
            });

            setBrandVideosData(brandData?.videos)
            setIsLoading(false)
        }
        catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const ListItem = ({ item, index }) => {
        const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true
        });

        if (inView) localStorage.setItem(Variables.scrollbrandVideoCount, index + 1)
        return (
            <div ref={ref} className='bottom-margin-60'>
                <VideoComponent
                    source={'Brand Profile'}
                    data={item}
                    index={index}
                    onClickVideo={() => history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Brand Profile' })}
                />
            </div>
        )
    }

    const SocialView = () => {
        return (
            <div className='common-social-list'>
                {brandData?.linkedinId &&
                    <div
                        onClick={() => {
                            window.open(brandData?.linkedinId, "_blank")
                            trackEvents.trackLinkedinLinkClick({ name: brandData?.name, link: brandData?.linkedinId, source: 'Brand Profile' })
                        }}
                        className='common-profile-social-view'>
                        <img
                            src={IMAGES.linkedin}
                            alt={'linkedin icon'}
                        />
                        <div>LinkedIn</div>
                    </div>
                }
                {brandData?.twitterId &&
                    <div
                        onClick={() => {
                            window.open(brandData?.twitterId, "_blank")
                            trackEvents.trackTwitterLinkClick({ name: brandData?.name, link: brandData?.twitterId, source: 'Brand Profile' })
                        }}
                        className='common-profile-social-view'>
                        <img
                            src={IMAGES.twitter}
                            alt={'twitter icon'}
                        />
                        <div>Twitter</div>
                    </div>
                }
                {brandData?.website &&
                    <div
                        onClick={() => {
                            window.open(brandData?.website, "_blank")
                            trackEvents.trackWebsiteLinkClick({ name: brandData?.name, link: brandData?.website, source: 'Brand Profile' })
                        }}
                        className='common-profile-social-view'>
                        <img
                            src={IMAGES.website}
                            alt={'website icon'}
                        />
                        <div>Website</div>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <Layout>
                <div className={`${isMobile ? (fetchMarketingUser() ? 'bottom-marketing-margin' : 'mobile-bottom-layout') : 'common-layout-margin'}`}>
                    {isMobile ?
                        <div className='common-profile-header-view'>
                            <div
                                style={{ gap: '16px' }}
                                className='row-flex-style'>
                                <div className='common-logo-outer-container'>
                                    {brandData?.logo &&
                                        <img
                                            src={brandData?.logo || ""}
                                            alt={'brand logo'}
                                            className='common-logo-style'
                                        />
                                    }
                                </div>
                                <div className='common-profile-title'>{brandData?.name || ""}</div>
                            </div>
                            <SocialView />
                        </div>
                        :
                        <div className='common-profile-header-view'>
                            <div className='common-logo-outer-container'>
                                {brandData?.logo &&
                                    <img
                                        src={brandData?.logo || ""}
                                        alt={'brand logo'}
                                        className='common-logo-style'
                                    />
                                }
                            </div>
                            <div>
                                <div className='common-profile-title'>{brandData?.name || ""}</div>
                                <SocialView />
                            </div>
                        </div>
                    }
                    <div className='videos-container'>Videos</div>
                    <div className={`top-margin-40 width-feed-card`}>
                        {isLoading ?
                            <FeedVideosSkeleton />
                            :
                            <>
                                {brandVideosData?.map((item, index) => {
                                    return (
                                        <ListItem
                                            item={item}
                                            index={index}
                                        />
                                    )
                                })
                                }
                            </>
                        }
                    </div>
                </div>
            </Layout >
        </>
    )
}

export default BrandProfile

