import axios from "axios";
import { trackEvents } from "../../constants/analyticsService";
import { ENV } from "../../constants/global";

const instance = axios.create({
    baseURL: ENV == 'dev' ? 'https://asia-south1-hellomeets-dev-55302.cloudfunctions.net/appV2/' : 'https://asia-south1-ytapi-371521.cloudfunctions.net/appV2/',
    timeout: 15000
});

// let startTime = null
// let endTime = null

instance.interceptors.request.use(
    function (config) {
        // console.log("Request-----", config);
        // startTime = Date.now();
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        // console.log("Response-----", response?.request?.responseURL);
        // endTime = Date.now();
        // trackEvents.trackApiTime({
        //     apiUrl: response?.request?.responseURL,
        //     apiTime: ((endTime - startTime) / 1000).toFixed(2) || 0
        // })
        return response;
    },
    async function (err) {
        return Promise.reject(err);
    }
);

export default instance;