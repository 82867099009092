import { baseApiCall } from "./config/BaseApiCall";
import { GET_BRAND_PROFILE_API, GET_HISTORY_PAGE_VIDEO_API, GET_HOME_PAGE_VIDEO_API, GET_PLAYER_FEED_API, GET_SEARCH_FEED_API, GET_SPEAKER_PROFILE_API, GET_YOUTUBE_DESCRIPTION_API } from "./config/apiEndPoints";

export const getHomePageVideoApiCall = (query) => {
    return baseApiCall({
        url: GET_HOME_PAGE_VIDEO_API + query,
        method: "get",
    });
};

export const getHistoryPageVideoApiCall = (query) => {
    return baseApiCall({
        url: GET_HISTORY_PAGE_VIDEO_API + query,
        method: "get",
    });
};

export const getPlayerFeedApiCall = (query) => {
    return baseApiCall({
        url: GET_PLAYER_FEED_API + query,
        method: "get",
    });
};

export const getSearchFeedApiCall = (query) => {
    return baseApiCall({
        url: GET_SEARCH_FEED_API + query,
        method: "get",
    });
};

export const getBrandProfileApiCall = (query) => {
    return baseApiCall({
        url: GET_BRAND_PROFILE_API + query,
        method: "get",
    });
};

export const getSpeakerProfileApiCall = (query) => {
    return baseApiCall({
        url: GET_SPEAKER_PROFILE_API + query,
        method: "get",
    });
};

export const getYoutubeDescriptionApiCall = (videoId) => {
    return baseApiCall({
        url: GET_YOUTUBE_DESCRIPTION_API + `?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_KEY}&part=snippet`,
        method: "get",
    });
};
