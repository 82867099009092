export const Variables = {
    userData: '@userData',
    viewVideo: '@viewVideo',
    totalWatchTime: 'totalWatchTime',
    videoData: `@videoData`,
    onboardingModal: '@onboardingModal',
    tempId: '@tempId',
    isFirstTime: '@isFirstTime',
    userTotalWatchTime: '@userTotalWatchTime',
    marketingUser: '@marketingUser',
    videoTimeStamp: '@videoTimeStamp',
    scrollhomeVideoCount: '@scrollhomeVideoCount',
    scrollbrandVideoCount: '@scrollbrandVideoCount',
    scrollspeakerVideoCount: '@scrollspeakerVideoCount',
    scrollTopicVideoCount: '@scrollTopicVideoCount',
    scrollCategoryVideoCount: '@scrollCategoryVideoCount',
    scrollPlayerVideoCount: '@scrollPlayerVideoCount',
    videoSlotTime: '@videoSlotTime',
    localRole: '@localRole',
    searchHistory: '@searchHistory',
    latestTopicArray: '@latestTopicArray'
};