import React, { useEffect, useState } from 'react'
import './Search.css'
import Layout from '../../component/Layout'
import { Typography, useMediaQuery } from '@mui/material'
import { COLORS } from '../../constants/colors'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FeedVideosSkeleton from '../../component/Skeleton/FeedVideosSkeleton'
import VideoComponent from '../../component/VideoComponent'
import { useHistory } from 'react-router-dom'
import { ENV, ROUTES, fetchMarketingUser, getQueryString } from '../../constants/global'
import RefreshIcon from '@mui/icons-material/Refresh';
import { trackEvents } from '../../constants/analyticsService'
import { getSearchData } from '../../redux/action/SearchAction'
import { Variables } from '../../constants/variable'
import { push, ref, set } from 'firebase/database'
import { database } from '../../App'
import { useInView } from 'react-intersection-observer'

const SearchResult = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const isMobile = useMediaQuery('(max-width:767px)');

    const { source } = location.state ?? ''

    const { searchPageData, userData } = useSelector((state) => state.common);
    const [searchedData, setSearchedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const type = location?.pathname?.split("/")?.[2]?.toUpperCase()
    const name = location?.pathname?.split("/")?.[3]

    useEffect(() => {
        if (!type || !name) return history.push("/")
        setIsLoading(true);
        if (type == 'TOPIC') trackEvents.trackTopicPage({ name: name, source: source || 'Link' })
        else if (type == 'CATEGORY') trackEvents.trackCategoryPage({ name: name, source: source || 'Link' })
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });

        if (!searchPageData) checkTempId()
    }, [])

    useEffect(() => {
        if (searchPageData) {
            setSearchedData(fetchVideos());
            setIsLoading(false);
        }
    }, [searchPageData])

    const checkTempId = async () => {
        const id = localStorage.getItem(Variables.tempId)
        if (!id) {
            const tempUserID = push(ref(database, '/tempusers')).key;
            const tempUserRef = ref(database, `/tempusers/${tempUserID}`);
            await set(tempUserRef, {
                name: '',
            });
            localStorage.setItem(Variables.tempId, tempUserID)
            fetchPageData(tempUserID)
        }
        else fetchPageData(id)
    }

    const fetchPageData = (tId) => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tId
        }
        dispatch(getSearchData(getQueryString(finalQuery)))
    }

    const fetchVideos = () => {
        let dataToSet = searchPageData?.videos || [];
        switch (type) {
            case 'CATEGORY':
                dataToSet = dataToSet.filter(item => item?.subCategory?.includes(name));
                break;
            case 'TOPIC':
                dataToSet = dataToSet.filter(item => item?.topic == name);
                break;
        }
        return dataToSet
    }

    const logo = (type == 'TOPIC' ? searchPageData?.topicList : searchPageData?.subCategoryList)?.find(i => i.value.name == name)?.value?.logo || ""

    const ListItem = ({ item, index }) => {
        const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true
        });

        if (inView) localStorage.setItem(type == 'TOPIC' ? Variables.scrollTopicVideoCount : Variables.scrollCategoryVideoCount, index + 1)
        return (
            <div ref={ref} className='bottom-margin-60'>
                <VideoComponent
                    source={type == 'CATEGORY' ? "Category" : "Topic"}
                    data={item}
                    index={index}
                    onClickVideo={() => history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: type == 'CATEGORY' ? "Category" : "Topic" })}
                />
            </div>
        )
    }

    return (
        <>
            <Layout>
                <div className={`${isMobile ? (fetchMarketingUser() ? 'bottom-marketing-margin' : 'mobile-bottom-layout') : 'common-layout-margin'}`}>
                    <div
                        style={{ display: 'flex' }}
                        className='common-profile-header-view'>
                        <div className='common-logo-outer-container'>
                            {logo &&
                                <img
                                    src={logo || ""}
                                    alt={'logo'}
                                    className='common-logo-style'
                                />
                            }
                        </div>
                        <div>
                            <div className='common-profile-title'>{name || ""}</div>
                            <div className='videos-count-style'>{searchedData?.length || 0} videos</div>
                        </div>
                    </div>
                    <div className='videos-container'>Videos</div>
                    <div className={`video-list-container width-feed-card top-margin-40`}>
                        {isLoading ?
                            <FeedVideosSkeleton />
                            :
                            <>
                                {searchedData?.length == 0 ?
                                    <>
                                        <div className='top-margin-110 history-signin-container'>
                                            <RefreshIcon style={{ width: '60px', height: '60px' }} />
                                            <Typography className='top-margin-10' variant='h5' color={COLORS.BLUE}>No results found</Typography>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {searchedData?.map((item, index) => {
                                            return (
                                                <ListItem
                                                    item={item}
                                                    index={index}
                                                />
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SearchResult

