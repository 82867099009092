import mixpanel from 'mixpanel-browser';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { analytics } from '../App';
import { Variables } from './variable';
import { ENV } from './global';

export const initSegment = async () => {
    try {
        mixpanel.init(ENV == 'dev' ? '6637e0c8536ac3df147ab53c74fd76a6' : '3b19fc50e3621f855618be2c51f0569c')
    } catch (error) {
        console.error(error);
    }
}

export const MainEventTypes = {
    OPEN_VIDEO: 'OpenVideo',
    LIKE_VIDEO: 'LikeVideo',
    DISLIKE_VIDEO: 'DisLikeVideo',
    SHARE_VIDEO_POPUP: 'ShareVideoPopup',
    SHARE_VIDEO: 'ShareVideo',
    SHARE_VIDEO_TO_LINKEDIN: 'ShareVideoToLinkedin',
    HOME_TAB: 'HomeTab',
    SEARCH_TAB: 'SearchTab',
    HISTORY_TAB: 'HistoryTab',
    ABOUT_US_TAB: 'AboutUsTab',
    SHARE_FEEDBACK: 'ShareFeedback',
    INSTALL_APP: 'InstallApp',
    PROFILE_OPEN: 'Profile',
    UPDATE_NAME: 'UpdateName',
    OPEN_APP: 'OpenApp',
    CONNECT_VIA_POPUP: 'ConnectViaPopup',
    LINKEDIN_LINK_CLICK: 'LinkedinLinkClick',
    TWITTER_LINK_CLICK: 'TwitterLinkClick',
    WEBSITE_LINK_CLICK: 'WebsiteLinkClick',
    COPY_LINK: 'CopyLink',
    LOGIN: 'Login',
    SIGN_OUT: 'Signout',
    OPEN_HOME_SCREEN: 'OpenHomeScreen',
    CLICK_BRAND_LOGO: 'ClickBrandLogo',
    BRAND_PROFILE: 'BrandProfile',
    SPEAKER_PROFILE: 'SpeakerProfile',
    PILLS_CLICK: 'Pills',
    TRENDING_VIDEOS_LEFT_ARROW_CLICK: 'TrendingVideosLeftArrow',
    TRENDING_VIDEOS_RIGHT_ARROW_CLICK: 'TrendingVideosRightArrow',
    VIEW_VIDEO: 'ViewVideo',
    CLICK_SEARCH: 'ClickSearch',
    ONBOARDING_POPUP: 'OnboardingPopup',
    ONBOARDING_POPUP_TOPIC: 'OnboardingPopupTopic',
    OPEN_VIDEO_VIA_POST: 'OpenVideoViaPost',
    OPEN_APP_VIA_POST: 'OpenAppViaPost',
    FORCE_LOGIN_POPUP: 'ForceLoginPopup',
    CLICK_SPEAKER_LOGO: 'ClickSpeakerLogo',
    CLOSE_SOFT_LOGIN_POPUP: 'CloseSoftLoginPopup',
    INSTALL_APP_PLAY_STORE_CLICK: 'InstallAppPlayStoreClick',
    INSTALL_APP_APP_STORE_CLICK: 'InstallAppAppStoreClick',
    DIFFERENT_SITE_USER: 'DifferentSiteUser',
    ERROR_HOME_FEED_API: 'ErrorHomeFeedApi',
    TOPIC_PILLS: 'TopicPills',
    TOPIC_PAGE: 'TopicPage',
    CATEGORY_PAGE: 'CategoryPage',
    LOGIN_ERROR: 'LoginError',
    NAME_CONTINUE: 'NameContinue',
    CLICK_DESCRIPTION_TIMESTAMP: 'ClickDescriptionTimeStamp',
    CLICK_DESCRIPTION_LINK: 'ClickDescriptionLink',
    VIDEO_PLAYBACK_RATE: 'VideoPlayBackRate',
    API_TIME: 'ApiTime',
    ONBOARDING_POPUP_ROLE: 'OnboardingPopupRole',
    WHATSAPP_COMMUNITY_BUTTON: 'WhatsappCommnunityButton',
    CONTINUE_WATCHING_LEFT_ARROW_CLICK: 'ContinueWatchingLeftArrow',
    CONTINUE_WATCHING_RIGHT_ARROW_CLICK: 'ContinueWatchingRightArrow',
}

export const fireEvent = (mainEventType, data = {}) => {
    mixpanel.track(mainEventType, data)
    logEvent(analytics, mainEventType, data)
    console.log(mainEventType, ":", data)
}

export const identifyEvent = (userId, mobileNumber) => {
    mixpanel.alias(userId);
    mixpanel.identify(userId, {
        mobile: mobileNumber
    });
    mixpanel.people.set({
        $mobile: mobileNumber
    });

    setUserId(analytics, userId)
    setUserProperties(analytics, {
        mobileNumber: mobileNumber
    });
}

export const resetIdentity = () => {
    mixpanel.reset();
    setUserId(analytics, null)
    setUserProperties(analytics, {
        username: null,
        mobileNumber: null
    });
}

export const setAnalyticsUserName = (userId, userName = "") => {
    mixpanel.identify(userId, {
        name: userName,
    });
    mixpanel.people.set({
        $name: userName
    });

    setUserProperties(analytics, { username: userName });
}

export const setAnalyticsRole = (userId, role = "") => {
    mixpanel.identify(userId, {
        userRole: role,
    });
    mixpanel.people.set({
        userRole: role
    });
    setUserProperties(analytics, { userRole: role });
}



export const trackEvents = {
    trackOpenVideo: (data) => {
        fireEvent(MainEventTypes.OPEN_VIDEO, data);
    },
    trackLikeVideo: (data) => {
        fireEvent(MainEventTypes.LIKE_VIDEO, data);
    },
    trackDisLikeVideo: (data) => {
        fireEvent(MainEventTypes.DISLIKE_VIDEO, data);
    },
    trackShareVideoPopup: (data) => {
        fireEvent(MainEventTypes.SHARE_VIDEO_POPUP, data);
    },
    trackShareVideo: (data) => {
        fireEvent(MainEventTypes.SHARE_VIDEO, data);
    },
    trackLInkedinShareVideo: (data) => {
        fireEvent(MainEventTypes.SHARE_VIDEO_TO_LINKEDIN, data);
    },
    trackHomeTab: () => {
        fireEvent(MainEventTypes.HOME_TAB);
    },
    trackSearchTab: () => {
        fireEvent(MainEventTypes.SEARCH_TAB);
    },
    trackHistoryTab: () => {
        fireEvent(MainEventTypes.HISTORY_TAB);
    },
    trackAboutUsTab: () => {
        fireEvent(MainEventTypes.ABOUT_US_TAB);
    },
    trackShareFeedback: () => {
        fireEvent(MainEventTypes.SHARE_FEEDBACK);
    },
    trackInstallApp: () => {
        fireEvent(MainEventTypes.INSTALL_APP);
    },
    trackProfileOpen: (data) => {
        fireEvent(MainEventTypes.PROFILE_OPEN, data);
    },
    trackUpdateName: (data) => {
        fireEvent(MainEventTypes.UPDATE_NAME, data)
    },
    trackOpenApp: (value) => {

        let distinctId = ""
        let mobileNumber = ""

        const userData = JSON.parse(localStorage.getItem(Variables.userData))

        if (userData && userData?.uid) {
            distinctId = userData?.uid
            mobileNumber = userData?.phoneNumber
        }
        else distinctId = mixpanel.get_distinct_id()

        mixpanel.identify(distinctId)

        mixpanel.people.set({
            $mobile: mobileNumber,
            "Mixpanel Library": "web"
        });

        const isFirstTime = localStorage.getItem(Variables.isFirstTime)
        if (isFirstTime) fireEvent(MainEventTypes.OPEN_APP, { NewUser: false, source: value || "" })
        else {
            mixpanel.people.set({
                "source": value || "",
            });
            localStorage.setItem(Variables.isFirstTime, true)
            fireEvent(MainEventTypes.OPEN_APP, { NewUser: true, source: value || "" })
        }
    },
    trackConnectViaPopup: (data) => {
        fireEvent(MainEventTypes.CONNECT_VIA_POPUP, data)
    },
    trackLinkedinLinkClick: (data) => {
        fireEvent(MainEventTypes.LINKEDIN_LINK_CLICK, data)
    },
    trackTwitterLinkClick: (data) => {
        fireEvent(MainEventTypes.TWITTER_LINK_CLICK, data)
    },
    trackWebsiteLinkClick: (data) => {
        fireEvent(MainEventTypes.WEBSITE_LINK_CLICK, data)
    },
    trackCopyLink: (data) => {
        fireEvent(MainEventTypes.COPY_LINK, data)
    },
    trackLogin: (data) => {
        fireEvent(MainEventTypes.LOGIN, data);
    },
    trackSignOut: () => {
        fireEvent(MainEventTypes.SIGN_OUT);
    },
    trackOpenHomePage: () => {
        fireEvent(MainEventTypes.OPEN_HOME_SCREEN);
    },
    trackClickBrandLogo: (data) => {
        fireEvent(MainEventTypes.CLICK_BRAND_LOGO, data);
    },
    trackBrandProfile: (data) => {
        fireEvent(MainEventTypes.BRAND_PROFILE, data);
    },
    trackSpeakerProfile: (data) => {
        fireEvent(MainEventTypes.SPEAKER_PROFILE, data);
    },
    trackPillsClick: (data) => {
        fireEvent(MainEventTypes.PILLS_CLICK, data);
    },
    trackTrendingVideoLeftArrowClick: () => {
        fireEvent(MainEventTypes.TRENDING_VIDEOS_LEFT_ARROW_CLICK);
    },
    trackTrendingVideoRightArrowClick: () => {
        fireEvent(MainEventTypes.TRENDING_VIDEOS_RIGHT_ARROW_CLICK);
    },
    trackViewVideo: (data) => {
        fireEvent(MainEventTypes.VIEW_VIDEO, data);
    },
    trackClickSearch: (data) => {
        fireEvent(MainEventTypes.CLICK_SEARCH, data);
    },
    trackOnboardingPopup: (data) => {
        fireEvent(MainEventTypes.ONBOARDING_POPUP, data);
    },
    trackOnboardingPopupTopic: (data) => {
        fireEvent(MainEventTypes.ONBOARDING_POPUP_TOPIC, data);
    },
    trackOpenVideoViaPost: (data) => {
        fireEvent(MainEventTypes.OPEN_VIDEO_VIA_POST, data);
    },
    trackOpenAppViaPost: (data) => {
        fireEvent(MainEventTypes.OPEN_APP_VIA_POST, data);
    },
    trackForceLoginPopup: () => {
        fireEvent(MainEventTypes.FORCE_LOGIN_POPUP);
    },
    trackClickSpeakerLogo: (data) => {
        fireEvent(MainEventTypes.CLICK_SPEAKER_LOGO, data);
    },
    trackCloseSoftLoginPopup: () => {
        fireEvent(MainEventTypes.CLOSE_SOFT_LOGIN_POPUP);
    },
    trackInstallAppPlayStoreClick: (data) => {
        fireEvent(MainEventTypes.INSTALL_APP_PLAY_STORE_CLICK, data);
    },
    trackInstallAppAppStoreClick: (data) => {
        fireEvent(MainEventTypes.INSTALL_APP_APP_STORE_CLICK, data);
    },
    trackDifferentSiteUser: (data) => {
        fireEvent(MainEventTypes.DIFFERENT_SITE_USER, data);
    },
    trackErrorHomeFeedApi: (data) => {
        fireEvent(MainEventTypes.ERROR_HOME_FEED_API, data);
    },
    trackTopicPills: (data) => {
        fireEvent(MainEventTypes.TOPIC_PILLS, data);
    },
    trackTopicPage: (data) => {
        fireEvent(MainEventTypes.TOPIC_PAGE, data);
    },
    trackCategoryPage: (data) => {
        fireEvent(MainEventTypes.CATEGORY_PAGE, data);
    },
    trackLoginError: (data) => {
        fireEvent(MainEventTypes.LOGIN_ERROR, data);
    },
    trackNameContinue: (data) => {
        fireEvent(MainEventTypes.NAME_CONTINUE, data);
    },
    trackClickDescriptionTimeStamp: (data) => {
        fireEvent(MainEventTypes.CLICK_DESCRIPTION_TIMESTAMP, data);
    },
    trackClickDescriptionLink: (data) => {
        fireEvent(MainEventTypes.CLICK_DESCRIPTION_LINK, data);
    },
    trackVideoPlayBackRate: (data) => {
        fireEvent(MainEventTypes.VIDEO_PLAYBACK_RATE, data);
    },
    trackApiTime: (data) => {
        fireEvent(MainEventTypes.API_TIME, data);
    },
    trackOnboardingPopupRole: (data) => {
        mixpanel.people.set({
            userRole: data.role || ""
        });
        fireEvent(MainEventTypes.ONBOARDING_POPUP_ROLE, data);
    },
    trackWhatsappCommunityButton: () => {
        fireEvent(MainEventTypes.WHATSAPP_COMMUNITY_BUTTON);
    },
    trackContinueWatchingLeftArrowClick: () => {
        fireEvent(MainEventTypes.CONTINUE_WATCHING_LEFT_ARROW_CLICK);
    },
    trackContinueWatchingRightArrowClick: () => {
        fireEvent(MainEventTypes.CONTINUE_WATCHING_RIGHT_ARROW_CLICK);
    },
}