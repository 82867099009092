import React, { cloneElement } from 'react';
import LayoutAppBar from './AppBar';
import Sidebar from './Sidebar';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen } from '../redux/action/UserAction';
import BottomBar from './BottomBar';
import { fetchMarketingUser } from '../constants/global';
import { useLocation } from 'react-router-dom';

const Layout = ({ children, searchText, setSearchText = () => { }, showHeader = true }) => {
    const isMobile = useMediaQuery('(max-width:991px)')
    const isTablet = useMediaQuery('(min-width:991px) and (max-width:1190px)')
    const dispatch = useDispatch()
    const location = useLocation();
    const { drawerOpen } = useSelector((state) => state.common);

    return (
        <>
            {showHeader &&
                <LayoutAppBar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onclick={() => dispatch(setDrawerOpen(!drawerOpen))} />
            }
            {!isMobile &&
                <Sidebar
                    isOpen={!isMobile && drawerOpen}
                    onClose={() => dispatch(setDrawerOpen(false))}
                />
            }
            <div className={(location?.pathname?.includes('video') && !isMobile) ? 'left-margin-15' : (isMobile ? '' : ((drawerOpen && !isTablet) ? 'left-margin-250' : 'left-margin-90'))}>
                {cloneElement(children, { drawerOpen })}
            </div>
            {((fetchMarketingUser() && isMobile) || isMobile) && <BottomBar />}
        </>
    )
}
export default Layout