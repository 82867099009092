import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../constants/global";
import { useHistory, useLocation } from 'react-router-dom';
import './Component.css';
import CloseIcon from '@mui/icons-material/Close';
import { setSoftLoginPopup } from "../redux/action/UserAction";
import LogoutIcon from '@mui/icons-material/Logout';
import { trackEvents } from "../constants/analyticsService";

const SoftLogin = () => {
    const { softLoginPopup, userData } = useSelector((state) => state.common);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    if (!softLoginPopup || userData || location.pathname == "/" + ROUTES.LOGIN || true) return null
    return (
        <div className="soft-login-container">
            <div className="soft-login">
                <div className="align-center">
                    <div className="right-margin-10 d-flex">
                        <LogoutIcon className="login-logo" />
                    </div>
                    <dv>
                        <div className="title">To get personally recommended videos for you.</div>
                        <div className="subtitle">Log in to see videos from different speakers and brands.</div>
                    </dv>
                </div>
                <div>
                    <Button className="soft-login-button" variant="contained" onClick={() => history.push(ROUTES.LOGIN)}>Log in</Button>
                </div>
                <div className="close-button-container" onClick={() => {
                    trackEvents.trackCloseSoftLoginPopup()
                    dispatch(setSoftLoginPopup(false))
                }}>
                    <CloseIcon style={{ color: 'gray' }} />
                </div>
            </div>
        </div>
    )
}

export default SoftLogin;