import { useMediaQuery, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import './Home.css'
import Layout from '../../component/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { getHomePageVideo } from '../../redux/action/HomePageVideoAction'
import { ENV, ROUTES, applyHomeSearch, fetchMarketingUser, getQueryString } from '../../constants/global'
import { useHistory, useLocation } from 'react-router-dom';
import TrendingVideosSkeleton from '../../component/Skeleton/TrendingVideosSkeleton'
import VideoComponent from '../../component/VideoComponent'
import VideoComponentCarousel from '../../component/VideoComponentCarousel'
import ContinueWatchingVideosSkeleton from '../../component/Skeleton/ContinueWatchingVideosSkeleton'
import FeedVideosSkeleton from '../../component/Skeleton/FeedVideosSkeleton'
import { getUserData, setDrawerOpen, setTopicExpanded } from '../../redux/action/UserAction'
import { trackEvents } from '../../constants/analyticsService'
import OnboardingModal from '../../component/OnboardingModal'
import { Variables } from '../../constants/variable'
import { push, ref, set } from 'firebase/database'
import { database } from '../../App'
import { useInView } from "react-intersection-observer";
import { getSearchData } from '../../redux/action/SearchAction'
import CategorySkeleton from '../../component/Skeleton/CategorySkeleton'
import { IMAGES } from '../../constants/images'
import RefreshIcon from '@mui/icons-material/Refresh';
import { COLORS } from '../../constants/colors'
import CloseIcon from '@mui/icons-material/Close';

const Home = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const location = useLocation();
    const targetRef = useRef(null);
    const { userInfo, userData, homePageVideo, homeVideoLoading, trendingVideos, continueWatchingVideos, searchPageData, searchPageDataLoading, playlists, topicExpanded } = useSelector((state) => state.common);
    const isMobile = useMediaQuery('(max-width:767px)');
    const [filterdVideos, setFilteredVideos] = useState([]);
    const [filterdPlaylist, setFilteredPlaylist] = useState([]);
    const [onboardingModal, setOnboardingModal] = useState(false)
    const [topicList, setTopicList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [searchFocus, setSearchFocus] = useState(false)

    const userRole = localStorage.getItem(Variables.localRole)
    const tempId = localStorage.getItem(Variables.tempId)
    const [latestTopic, setLatestTopic] = useState(() => {
        return JSON.parse(localStorage.getItem(Variables.latestTopicArray)) || [];
    });
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (!isMobile) setSearchFocus(false)
    }, [isMobile])

    useEffect(() => {
        setFilteredPlaylist(playlists)
    }, [playlists])

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            setFilteredVideos(applyHomeSearch(homePageVideo, searchText))
            setFilteredPlaylist(applyHomeSearch(playlists, searchText))
            if (searchText != "") setTopicList(searchPageData?.topicList?.filter(topic => topic?.value?.name?.toLowerCase().includes(searchText?.toLowerCase()?.trim())))
            if (searchText != "") trackEvents.trackClickSearch({ text: searchText, source: 'Search' })
        }, 500);

        return () => clearTimeout(debounceSearch);
    }, [searchText]);

    useEffect(() => {
        dispatch(setDrawerOpen(true))
        const value = params.get('utm_source')
        if (value) {
            trackEvents.trackOpenAppViaPost({
                source: value
            })
        }
    }, [])

    useEffect(() => {
        setFilteredVideos(homePageVideo);
        if (homePageVideo?.length > 0) {
            const res = localStorage.getItem(Variables.onboardingModal)
            if (res) setOnboardingModal(false)
            else setOnboardingModal(true)
        }
    }, [homePageVideo])

    useEffect(() => {
        const seniorRole = ['PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product']
        if (seniorRole?.includes(userInfo?.role || userRole)) {
            if (latestTopic?.length > 0) {
                const latestTopicData = searchPageData?.topicList?.filter(i => latestTopic?.includes(i.value.name))
                const remainingTopicData = searchPageData?.topicList?.filter(i => !latestTopic?.includes(i.value.name))

                const secondArrayMap = new Map(latestTopicData.map(item => [item.value.name, item]));
                const sortedSecondArray = latestTopic?.map(name => secondArrayMap.get(name));
                const newArray = [...sortedSecondArray, ...remainingTopicData]
                setTopicList(Object.assign([], newArray))
            }
            else {
                setTopicList(searchPageData?.topicList)
            }
        }
        else {
            const sortOrder = ["Fundamentals", "Design", "Tech", "Analytics", "User Research", "Career Development", "User Growth", "Product Strategy", "Retention & Engagement", "Revenue", "Team Management"];

            const data = searchPageData?.topicList ? [...searchPageData.topicList] : []
            data?.sort((a, b) => {
                const aName = a.value.name;
                const bName = b.value.name;

                const aIndex = sortOrder.indexOf(aName);
                const bIndex = sortOrder.indexOf(bName);

                if (aIndex !== -1 && bIndex !== -1) {
                    return aIndex - bIndex;
                } else if (aIndex !== -1) {
                    return -1;
                } else if (bIndex !== -1) {
                    return 1;
                } else {
                    return 0;
                }
            });
            if (latestTopic?.length > 0) {
                const latestTopicData = data?.filter(i => latestTopic?.includes(i.value.name))
                const remainingTopicData = data?.filter(i => !latestTopic?.includes(i.value.name))

                const secondArrayMap = new Map(latestTopicData.map(item => [item.value.name, item]));
                const sortedSecondArray = latestTopic?.map(name => secondArrayMap.get(name));
                const newArray = [...sortedSecondArray, ...remainingTopicData]
                setTopicList(Object.assign([], newArray))
            }
            else {
                setTopicList(data)
            }

        }
    }, [userInfo, searchPageData, userRole, latestTopic])

    useEffect(() => {
        dispatch(getUserData())
        trackEvents.trackOpenHomePage();
        const res = localStorage.getItem(Variables.userData)
        if (!res) tempFunction()
        else {
            getPageData()
            getSearchDataFunc()
        }
    }, [])

    const tempFunction = async () => {
        const id = localStorage.getItem(Variables.tempId)
        if (!id) {
            const tempUserID = push(ref(database, '/tempusers')).key;
            const tempUserRef = ref(database, `/tempusers/${tempUserID}`);
            await set(tempUserRef, {
                name: '',
            });
            localStorage.setItem(Variables.tempId, tempUserID)
            getPageData(tempUserID)
            getSearchDataFunc(tempUserID)
        }
        else {
            getPageData(id)
            getSearchDataFunc(id)
        }
    }

    const getSearchDataFunc = (tId = tempId) => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tId
        }
        dispatch(getSearchData(getQueryString(finalQuery)))
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    const getPageData = (tId = tempId) => {
        let finalQuery = {}
        finalQuery.env = ENV
        if (userData) finalQuery.userId = userData?.uid
        if (!userData && tId) {
            finalQuery.isTempUser = true
            finalQuery.userId = tId
        }
        dispatch(getHomePageVideo(getQueryString(finalQuery)))
    }

    const onChangeTopic = (name) => {
        let dataToSet = homePageVideo;

        const userRole = localStorage.getItem(Variables.localRole)
        const seniorRole = ['PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product']
        if (seniorRole?.includes(userRole)) {
            const selectedSeniorHighLevel = dataToSet?.filter(item => item?.topic == name && (item?.level == 'L2' || item?.level == 'L1+L2' || !item?.level))
            const otherSeniorHighLevel = dataToSet?.filter(item => item?.topic != name && (item?.level == 'L2' || item?.level == 'L1+L2' || !item?.level))
            const selectedSeniorLowLevel = dataToSet?.filter(item => item?.topic == name && item?.level == 'L1')
            const otherSeniorLowLevel = dataToSet?.filter(item => item?.topic != name && item?.level == 'L1')
            dataToSet = [...selectedSeniorHighLevel, ...otherSeniorHighLevel, ...selectedSeniorLowLevel, ...otherSeniorLowLevel];
        }
        else {
            const selectedJuniorHighLevel = dataToSet?.filter(item => item?.topic == name && (item?.level == 'L1' || item?.level == 'L1+L2' || !item?.level))
            const otherJuniorHighLevel = dataToSet?.filter(item => item?.topic != name && (item?.level == 'L1' || item?.level == 'L1+L2' || !item?.level))
            const selectedJuniorLowLevel = dataToSet?.filter(item => item?.topic == name && item?.level == 'L2')
            const otherJuniorLowLevel = dataToSet?.filter(item => item?.topic != name && item?.level == 'L2')
            dataToSet = [...selectedJuniorHighLevel, ...otherJuniorHighLevel, ...selectedJuniorLowLevel, ...otherJuniorLowLevel];
        }

        setFilteredVideos(dataToSet);
    }

    const handleTopic = (item) => {
        localStorage.setItem(Variables.latestTopicArray, JSON.stringify([...new Set([item.value.name, ...latestTopic])]))
        history.push('/' + ROUTES.SEARCH_RESULT + '/topic/' + item.value.name, { source: 'Home' })
    }

    const DotsComponent = ({ extra_style }) => {
        return (
            <div className={`dot-container ${extra_style}`}>
                {(Array(isMobile ? 2 : 5).fill())?.map((_, index) => {
                    return (
                        <div key={index} className='dot'></div>
                    )
                })}
            </div>
        )
    }

    const ListItem = ({ item, index }) => {
        const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true
        });
        if (item?.isDependent) return <></>

        if (inView) localStorage.setItem(Variables.scrollhomeVideoCount, index + 1)
        return (
            <div ref={ref} key={index} className='bottom-margin-60'>
                <VideoComponent
                    source={'Home'}
                    data={item}
                    index={index}
                    onClickVideo={() => {
                        if (item?.entity == 'Playlist') history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Home', playlistId: item?.id })
                        else history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Home' })
                    }}
                />
            </div>
        )
    }

    return (
        <>
            <Layout showHeader={!searchFocus}>
                <>
                    <div className={`${isMobile ? (fetchMarketingUser() ? 'bottom-marketing-margin' : 'mobile-bottom-layout') : 'common-layout-margin'} trending-video`}>
                        {!searchFocus &&
                            <div className='home-header-text-view'>
                                Are you a&nbsp;
                                <div className='home-header-yellow-view'>
                                    <span className='home-header-yellow-text'>Product Manager</span>
                                    {!isMobile && <img src={IMAGES.curve_line} className='curve-line-style' />}
                                </div>
                                &nbsp;eager to elevate your career to new heights?
                            </div>
                        }
                        {isMobile &&
                            <div className={searchFocus ? 'searchtext-header-search-bar-style' : 'header-search-bar-style'}>
                                <img
                                    src={IMAGES.search_icon}
                                    alt={'search icon'}
                                    className='search-icon-style'
                                />
                                <input
                                    onFocus={() => setSearchFocus(true)}
                                    value={searchText}
                                    className={'header-search-input'}
                                    type="text"
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                    }}
                                    placeholder="Search any product topic"
                                />
                                {searchFocus &&
                                    <CloseIcon
                                        onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth',
                                            });
                                            setSearchText("")
                                            setSearchFocus(false)
                                        }}
                                        style={{ color: 'gray' }}
                                        className='cursor-pointer'
                                    />
                                }
                            </div>
                        }
                        {!searchText &&
                            <>
                                <div className='home-title-style'>Trending Videos 🔥</div>
                                <div className={`${isMobile && 'trending-video-list-container left-margin-20'}`}>
                                    {homeVideoLoading ?
                                        <TrendingVideosSkeleton />
                                        :
                                        <VideoComponentCarousel
                                            slidesPerView={isMobile ? 1.1 : 2.6}
                                            type='TRENDING_VIDEOS'
                                            displayCount={true}
                                            data={trendingVideos}
                                            onClickVideo={(item) => history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Trending Videos' })}
                                        />
                                    }
                                </div>
                                {continueWatchingVideos?.length > 0 &&
                                    <div className='continue-watching-video continue-watching-background'>
                                        <div className='home-title-style'>Continue Watching</div>
                                        <div className={`continue-watching-video-list-container ${isMobile && 'left-margin-20'}`}>
                                            {homeVideoLoading ?
                                                <ContinueWatchingVideosSkeleton />
                                                :
                                                <VideoComponentCarousel
                                                    slidesPerView={isMobile ? 1.1 : 2.6}
                                                    type='CONTINUE_WATCHING'
                                                    displayProgressBar={true}
                                                    data={continueWatchingVideos}
                                                    onClickVideo={(item) => history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.id, { source: 'Continue Watching' })}
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <div className={searchText && !isMobile && 'top-margin-110'}>
                            {topicList?.length > 0 &&
                                <>
                                    <div className='align-space-block top-margin-40 bottom-margin-30'>
                                        <div style={{ marginBottom: '0px', marginTop: '0px' }} className='home-title-style'>{searchText ? "Results from Topics" : "Relevant Topics"}</div>
                                        {!isMobile && topicList?.length > 6 &&
                                            <div
                                                onClick={() => dispatch(setTopicExpanded(!topicExpanded))}
                                                className={`view-all-style`}>
                                                <div className='view-all-text'>{topicExpanded ? 'View Less' : 'View All'}</div>
                                                <img
                                                    src={topicExpanded ? IMAGES.chevron_up : IMAGES.chevron_down}
                                                    alt={'down-icon'}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {searchPageDataLoading ?
                                        <div className='bottom-margin-20 top-margin-20 width-100-per'>
                                            <CategorySkeleton />
                                        </div>
                                        :
                                        <>
                                            {isMobile ?
                                                <div ref={targetRef} className='home-mobile-topic-container'>
                                                    <div className='home-topic-container'>
                                                        {topicList?.slice(0, topicExpanded ? 100 : 4)?.map((item, index) => {
                                                            return (
                                                                <div key={index} className={`home-mobile-topic-block`} onClick={() => handleTopic(item)}>
                                                                    <div className='product-topic-view'>
                                                                        <img src={item?.value?.logo} />
                                                                    </div>
                                                                    <div className='home-topic-text'>{item?.value?.name || ""}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    {topicList?.length > 4 &&
                                                        <div className='justify-center'>
                                                            <div
                                                                onClick={() => {
                                                                    dispatch(setTopicExpanded(!topicExpanded))
                                                                    if (topicExpanded) targetRef?.current?.scrollIntoView({ behavior: 'smooth' });
                                                                }}
                                                                className={`view-all-style`}>
                                                                <div className='view-all-text'>{topicExpanded ? 'View Less' : 'View All'}</div>
                                                                <img
                                                                    src={topicExpanded ? IMAGES.chevron_up : IMAGES.chevron_down}
                                                                    alt={'down-icon'}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className='home-topic-container'>
                                                    {topicList?.slice(0, topicExpanded ? 100 : 6)?.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`home-topic-block`} onClick={() => handleTopic(item)}>
                                                                <div className='product-topic-view'>
                                                                    <img src={item?.value?.logo} />
                                                                </div>
                                                                <div className='home-topic-text'>{item?.value?.name || ""}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {filterdPlaylist?.length > 0 &&
                            <div className='playlist-background-style'>
                                <div className='home-title-style'>{searchText ? "Results from Playlists" : "Playlist curated for you"}</div>
                                <div className={`video-list-container width-feed-card`}>
                                    {filterdPlaylist?.map((item, index) => {
                                        return (
                                            <div key={index} className='bottom-margin-60'>
                                                <VideoComponent
                                                    source={'Playlists'}
                                                    data={item}
                                                    index={index}
                                                    onClickVideo={() => {
                                                        history.push('/' + ROUTES.VIDEO_PLAYING + '/' + item?.videoIdList?.[0], { source: 'Playlists', playlistId: item?.id })
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            <div className='home-title-style top-margin-50'>{searchText ? "Results from Videos" : "Personalised feed for you ⚡️"}</div>
                            <div className={`video-list-container width-feed-card`}>
                                {homeVideoLoading ?
                                    <FeedVideosSkeleton />
                                    :
                                    <>
                                        {filterdVideos?.length > 0 ?
                                            <>
                                                {filterdVideos?.map((item, index) => {
                                                    return (
                                                        <ListItem
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                })
                                                }
                                            </>
                                            :
                                            <div className='top-margin-85 history-signin-container'>
                                                <RefreshIcon style={{ width: '60px', height: '60px' }} />
                                                <Typography className='top-margin-10' variant='h5' color={COLORS.BLUE}>No results found</Typography>
                                                <Typography className='top-margin-10' variant='body1' color={COLORS.BLUE}>Try something else</Typography>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </Layout >
            {onboardingModal &&
                <OnboardingModal
                    onboardingModal={onboardingModal}
                    setOnboardingModal={setOnboardingModal}
                    onChangeTopic={onChangeTopic}
                />
            }
        </>
    )
}

export default Home

