import React, { useEffect } from 'react';
import { AppRoutes } from './route/AppRoutes';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store';
import { getAuth } from 'firebase/auth';
import { initSegment } from './constants/analyticsService';
import { getAnalytics } from 'firebase/analytics';
import { BrowserRouter as Router } from 'react-router-dom';
import { Variables } from './constants/variable';
import { ENV } from './constants/global';
import './App.css';

// Initiating mixpanel analytics
initSegment();

let theme = createTheme();
theme = responsiveFontSizes(theme);

const { store, persistor } = configureStore();

const firebaseConfig = {
  apiKey: "AIzaSyA9C5E4GrclMsn2oco1TJQG5LG6P_25Yws",
  authDomain: "ytapi-371521.firebaseapp.com",
  databaseURL: "https://ytapi-371521.asia-southeast1.firebasedatabase.app",
  projectId: "ytapi-371521",
  storageBucket: "ytapi-371521.appspot.com",
  messagingSenderId: "814164967534",
  appId: "1:814164967534:web:6c8c2f060e43582e51a67f",
  measurementId: "G-Y0MLMNR46H"
};

const devFirebaseConfig = {
  apiKey: "AIzaSyD2FkVswaN3w-q_KaUTxYrTqGQlXjmkEMQ",
  authDomain: "hellomeets-dev-55302.firebaseapp.com",
  databaseURL: "https://hellomeets-dev-55302-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hellomeets-dev-55302",
  storageBucket: "hellomeets-dev-55302.appspot.com",
  messagingSenderId: "112755267973",
  appId: "1:112755267973:web:67079f879f4cfd37c00cec",
  measurementId: "G-ZNMWXZPSWB"
};

const app = initializeApp(ENV == 'dev' ? devFirebaseConfig : firebaseConfig);
getAuth(app);
export const database = getDatabase(app);
export const analytics = getAnalytics(app);

function App() {

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem(Variables.marketingUser)
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <AppRoutes />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

