
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation, useHistory } from 'react-router-dom';
import { COLORS } from '../constants/colors';
import { Drawer, ListItemButton, useMediaQuery, IconButton } from '@mui/material';
import { ROUTES } from '../constants/global';
import BoltIcon from '@mui/icons-material/Bolt';
import { MainEventTypes, trackEvents } from '../constants/analyticsService';
import MenuIcon from '@mui/icons-material/Menu';
import { IMAGES } from '../constants/images';
import { setDrawerOpen } from '../redux/action/UserAction';
import { useDispatch } from 'react-redux';

const Sidebar = ({ isOpen, onClose }) => {
    const data = [{
        Icon: HomeIcon,
        title: 'Home',
        route: '/',
        addDivider: false,
        sendEvent: MainEventTypes.HOME_TAB
    }, {
        Icon: HistoryIcon,
        title: 'History',
        route: '/' + ROUTES.HISTORY,
        addDivider: false,
        sendEvent: MainEventTypes.HISTORY_TAB
    }, {
        Icon: InfoOutlinedIcon,
        title: 'About Us',
        route: 'https://about-us.hellomeets.com',
        addDivider: true,
        sendEvent: MainEventTypes.ABOUT_US_TAB
    }]

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()

    const isMobile = useMediaQuery('(max-width:991px)')
    const isTablet = useMediaQuery('(min-width:991px) and (max-width:1190px)')

    const TabComponent = () => {

        const handleSideMenuClick = (item) => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
            item.addDivider ? window.open(item.route, '_blank') : history.push(item.route);
            if (isMobile) onClose();
            switch (item.sendEvent) {
                case MainEventTypes.HOME_TAB:
                    trackEvents.trackHomeTab();
                    break;
                case MainEventTypes.SEARCH_TAB:
                    trackEvents.trackSearchTab();
                    break;
                case MainEventTypes.HISTORY_TAB:
                    trackEvents.trackHistoryTab();
                    break;
                case MainEventTypes.ABOUT_US_TAB:
                    trackEvents.trackAboutUsTab();
                    break;
            }
        }
        return (
            <List>
                {data?.map((item, index) => {
                    const Icon = item.Icon

                    return (
                        <div key={index}>
                            <ListItem>
                                <ListItemButton className={`${location.pathname === item.route && 'selected-menu'} menu-item display-between`}
                                    onClick={() => handleSideMenuClick(item)}>
                                    <div className='d-flex'>
                                        <Icon style={{ height: '24px', width: '24px', color: '#5A7184' }} />
                                        {!isTablet && <label className='side-bar-text left-margin-15 cursor-pointer' >{item.title}</label>}
                                    </div>
                                </ListItemButton>
                            </ListItem>
                        </div>
                    )
                })
                }
            </List >
        )
    }

    return (
        <>
            {location?.pathname?.includes('video') ?
                <>
                    {isOpen &&
                        <Drawer
                            anchor="left"
                            open={isOpen}
                            onClose={onClose}
                        >
                            <div className='side-bar-drawer-container'>
                                <div className='row-flex-style left-margin-15'>
                                    <IconButton onClick={() => dispatch(setDrawerOpen(false))} className='right-margin-10'>
                                        <MenuIcon />
                                    </IconButton>
                                    <img
                                        onClick={() => history.push('/')}
                                        src={IMAGES.hellomeets_logo}
                                        className='logo-style'
                                        alt={'Hellomeets-logo'}
                                    />
                                </div>
                                <div className='drawer-header-border' />
                                <TabComponent />
                            </div>
                        </Drawer>
                    }
                </>
                :
                <div className={(isOpen && !isTablet) ? 'side-bar-container' : "side-bar-close-container"}>
                    <TabComponent />
                </div>
            }
        </>
    );
};

export default Sidebar;
