import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const LogoutModal = ({ open, onClose, onLogout }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Are you sure you want to logout?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Logging out will end your session.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onLogout} color="primary">
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LogoutModal;
