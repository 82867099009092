import React, { useEffect, useMemo, useState } from 'react'
import './Profile.css'
import Layout from '../../component/Layout'
import { Button, Divider, TextField, Typography, useMediaQuery } from '@mui/material'
import { COLORS } from '../../constants/colors'
import LogoutIcon from '@mui/icons-material/Logout';
import { ENV, removeData, whstappFeedbackDefaultMessage, whstappFeedbackNumber } from '../../constants/global'
import { useDispatch, useSelector } from 'react-redux'
import { get, ref, update } from 'firebase/database';
import { database } from '../../App'
import LogoutModal from '../../component/LogoutModal'
import { setUserInfo } from '../../redux/action/UserAction'
import { useHistory } from 'react-router-dom';
import { getAppStore } from '../../redux/store'
import { USER_LOGGING_OUT } from '../../redux/config/actionTypes'
import { Variables } from '../../constants/variable'
import Loader from '../../component/Loader'
import { toast } from 'react-toastify'
import Toast from '../../component/Toast'
import { resetIdentity, setAnalyticsRole, setAnalyticsUserName, trackEvents } from '../../constants/analyticsService'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const options = [
    'Student/Intern', 'APM', 'PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product', 'Others'
];

const Profile = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:767px)');
    const { userData, userInfo } = useSelector((state) => state.common);
    const history = useHistory();
    const [username, setUsername] = useState('')
    const [usernameError, setUsernameError] = useState(false);
    const [logoutPopup, setLogoutPopup] = useState(false)
    const [openLoader, setOpenLoader] = useState(false);
    const [role, setRole] = useState('')
    const [otherRole, setOtherRole] = useState('');
    const [otherRoleError, setOtherRoleError] = useState(false)

    const userRef = useMemo(() => {
        if (!userData?.uid) return null;
        return ref(database, `/users/${userData?.uid}`)
    }, [])

    useEffect(() => {
        if (userInfo) {
            setUsername(userInfo?.name || "")
            setRole(userInfo?.role || "")
        }
    }, [userInfo]);

    const getUserInfo = () => {
        get(userRef).then((snapshot) => {
            const data = snapshot.val();
            dispatch(setUserInfo(data));
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    };

    const handleNameChange = (event) => {
        const inputUsername = event.target.value;
        if (!inputUsername) setUsernameError(true)
        else setUsernameError(false)
        setUsername(inputUsername);
    };

    const handleOtherRole = (event) => {
        const inputUsername = event.target.value;
        if (!inputUsername) setOtherRoleError(true);
        else setOtherRoleError(false)
        setOtherRole(inputUsername);
    };

    const onSaveChanges = async () => {
        if (!username) return setUsernameError(true);
        if (!role) {
            return toast.error('Please select your role', {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
        }
        if (role == 'Others' && !otherRole) return setOtherRoleError(true)
        setOpenLoader(true);
        if (userRef) {
            try {
                await update(userRef, { name: username, role: role != 'Others' ? role : otherRole });
                setAnalyticsUserName(userData?.uid, username || "")
                trackEvents.trackUpdateName({ name: username, role: role != 'Others' ? role : otherRole })
                setAnalyticsRole(userData?.uid, (role != 'Others' ? role : otherRole) || "")
                getUserInfo();
                setTimeout(() => {
                    setOpenLoader(false);
                    toast.success('Profile updated successfully.', {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }, 1000);
            }
            catch (err) {
                console.log(err, "err")
                toast.error('Something went wrong! Please try again.', {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
                setTimeout(() => {
                    setOpenLoader(false);
                }, 1000);
            }
        }
    }

    const onFeedback = () => {
        window.open(`https://wa.me/${whstappFeedbackNumber}?text=${encodeURIComponent(whstappFeedbackDefaultMessage)}`, '_blank')
        trackEvents.trackShareFeedback();
    }

    return (
        <>
            <Toast />
            <Loader open={openLoader} handleClose={() => setOpenLoader(false)} />
            <Layout>
                <div className={`common-layout-margin ${isMobile ? 'top-margin-85' : 'top-margin-81'}`}>
                    <Typography variant="h4" color={COLORS.BLUE} className='bold-text'>
                        Profile Settings<span>{ENV == 'dev' && ` ( ${ENV} )`}</span>
                    </Typography>
                    <Typography variant="body2" color={COLORS.GRAY} className='input-title-style'>
                        Full name
                    </Typography>
                    <TextField
                        className='input-container-style'
                        id="outlined-basic"
                        variant="outlined"
                        value={username}
                        fullWidth
                        onChange={handleNameChange}
                        error={usernameError}
                        helperText={usernameError ? 'Please enter a full name' : ''}
                    />
                    <Typography variant="body2" color={COLORS.GRAY} className='input-title-style'>
                        Role
                    </Typography>
                    <Dropdown
                        className='input-container-style bottom-margin-20'
                        value={role}
                        options={options}
                        placeholder="Select your role"
                        onChange={(item) => setRole(item.value)}
                    />
                    {role == 'Others' &&
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Enter your role"
                            variant="outlined"
                            className='input-container-style'
                            value={otherRole}
                            onChange={handleOtherRole}
                            error={otherRoleError}
                            helperText={otherRoleError ? 'Please enter your role' : ''}
                        />
                    }
                    <Typography variant="body2" color={COLORS.GRAY} className='input-title-style'>
                        Phone Number
                    </Typography>
                    <TextField
                        className='input-container-style bottom-margin-20'
                        id="outlined-basic"
                        variant="outlined"
                        disabled
                        defaultValue={userData?.phoneNumber || ''}
                        fullWidth
                    />
                    {(userInfo?.name != username || userInfo?.role != role) &&
                        <div className='row-flex-style bottom-margin-20'>
                            <Button
                                variant="contained"
                                className='button-container-style'
                                onClick={onSaveChanges}
                            >
                                Save all changes
                            </Button>
                            <Button
                                variant='outlined'
                                className='button-cancel-style'
                                onClick={() => {
                                    setUsername(userInfo?.name)
                                    setRole(userInfo?.role)
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    }
                    <Divider />
                    <div>
                        <Button
                            onClick={() => setLogoutPopup(true)}
                            variant='outlined'
                            className='button-logout-style'
                            startIcon={<LogoutIcon />}
                        >
                            Logout
                        </Button>
                    </div>

                    <div className={`install-app-container cursor-pointer bottom-margin-30`} onClick={onFeedback}>
                        <WhatsAppIcon className='feedback-icon-style' />
                        <div>Feedback</div>
                    </div>

                    <a href="https://www.hellomeets.com/privacy-policy" target="_blank">
                        <Typography variant="body2" color={COLORS.GRAY}>
                            Privacy Policy
                        </Typography>
                    </a>
                    {/* <Typography variant="body2" color={COLORS.GRAY} className='top-margin-15'>
                    v 1.23452
                </Typography> */}
                </div>
            </Layout >
            <LogoutModal
                open={logoutPopup}
                onClose={() => setLogoutPopup(false)}
                onLogout={() => {
                    setLogoutPopup(false)
                    removeData(Variables.userData)
                    removeData(Variables.localRole)
                    history.push('/')
                    getAppStore().dispatch({ type: USER_LOGGING_OUT })
                    trackEvents.trackSignOut();
                    resetIdentity()
                    window.location.reload()
                }}
            />
        </>
    )
}

export default Profile

