import { Button, Dialog, DialogContent, DialogContentText, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ROUTES } from "../constants/global";
import { useHistory } from 'react-router-dom';
import './Component.css';
import { useEffect } from "react";
import { trackEvents } from "../constants/analyticsService";

const ForceLogin = ({ videoId }) => {

    const { forceLoginPopup } = useSelector((state) => state.common);
    const history = useHistory();

    useEffect(() => {
        if (forceLoginPopup) trackEvents.trackForceLoginPopup()
    }, [forceLoginPopup])

    return (
        <Dialog
            open={forceLoginPopup}
        >
            <DialogContent>
                <DialogContentText>
                    <div className='d-flex flex-direction-cloumn' style={{ minWidth: '378px' }}>
                        <label className='play-video-tag'>Want to see more videos?</label>
                        <label variant='subtitle1' className='play-video-tag-text'>Please login to continue watching videos</label>
                        <div className='play-sign-in-button'>
                            <Button variant='text' className="bottom-margin-20" onClick={() => history.push("/" + ROUTES.LOGIN, { videoId: videoId })}>Log in</Button>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default ForceLogin;