import login_boy from '../assets/images/login_boy.svg'
import hellomeets_logo from '../assets/images/hellomeets_logo.svg'
import arrow_back from '../assets/images/arrow_back.svg'
import avatar from '../assets/images/avatar.svg'
import speaker_profile from '../assets/images/speaker_profile.svg'
import twitter_logo from '../assets/images/twitter-logo.svg'
import linkedin_logo from '../assets/images/linkedin-icon.svg'
import courses_logo from '../assets/images/courses-logo.svg'
import player_icon from '../assets/images/play-button-icon.svg'
import play_icon from '../assets/images/play_icon.svg'
import feed_user from '../assets/images/feed_user.svg'
import chevron_down from '../assets/images/chevron-down.svg'
import chevron_up from '../assets/images/chevron-up.svg'
import curve_line from '../assets/images/curve_line.svg'
import swiper_right_arrow from '../assets/images/swiper_right_arrow.svg'
import swiper_left_arrow from '../assets/images/swiper_left_arrow.svg'
import right_shadow from '../assets/images/right_shadow.svg'
import left_shadow from '../assets/images/left_shadow.svg'
import right_gray_shadow from '../assets/images/right_gray_shadow.svg'
import left_gray_shadow from '../assets/images/left_gray_shadow.svg'
import linkedin from '../assets/images/linkedin.svg'
import twitter from '../assets/images/twitter.svg'
import website from '../assets/images/website.svg'
import person_add from '../assets/images/person-add.svg'
import thumbs_up_empty from '../assets/images/thumbs_up_empty.svg'
import linkedin_blue from '../assets/images/linkedin_blue.svg'
import share_blue from '../assets/images/share_blue.svg'
import thumbs_up_green from '../assets/images/thumbs_up_green.svg'
import arrow_top_up from '../assets/images/arrow-top-up.svg'

export const IMAGES = {
    login_boy: login_boy,
    hellomeets_logo: hellomeets_logo,
    arrow_back: arrow_back,
    avatar: avatar,
    frame1: require('../assets/images/frame1.png'),
    frame2: require('../assets/images/frame2.png'),
    frame3: require('../assets/images/frame3.png'),
    frame4: require('../assets/images/frame4.png'),
    frame5: require('../assets/images/frame5.png'),
    frame6: require('../assets/images/frame6.png'),
    frame7: require('../assets/images/frame7.png'),
    frame8: require('../assets/images/frame8.png'),
    frame9: require('../assets/images/frame9.png'),
    frame10: require('../assets/images/frame10.png'),
    frame11: require('../assets/images/frame11.png'),
    speaker_profile: speaker_profile,
    twitter_logo: twitter_logo,
    linkedin_logo: linkedin_logo,
    courses_logo: courses_logo,
    player_icon: player_icon,
    google_play_store: require('../assets/images/google_play_store_logo.png'),
    apple_store: require('../assets/images/apple_logo.png'),
    playstore_qr_code: require('../assets/images/play-store-qr.png'),
    appstore_qr_code: require('../assets/images/apple-store-qr.png'),
    category_1: require('../assets/images/category1.png'),
    loader_icon: require('../assets/images/loader.png'),
    play_icon: play_icon,
    whatsapp_icon: require('../assets/images/whatsapp_icon.png'),
    feed_user: feed_user,
    chevron_down: chevron_down,
    chevron_up: chevron_up,
    curve_line: curve_line,
    search_icon: require('../assets/images/search_icon.png'),
    playlist_bg: require('../assets/images/playlist_bg.png'),
    swiper_right_arrow: swiper_right_arrow,
    swiper_left_arrow: swiper_left_arrow,
    right_shadow: right_shadow,
    left_shadow: left_shadow,
    right_gray_shadow: right_gray_shadow,
    left_gray_shadow: left_gray_shadow,
    linkedin: linkedin,
    twitter: twitter,
    website: website,
    person_add: person_add,
    thumbs_up_empty: thumbs_up_empty,
    linkedin_blue: linkedin_blue,
    share_blue: share_blue,
    thumbs_up_green: thumbs_up_green,
    arrow_top_up: arrow_top_up
}