import React, { useEffect, useMemo, useState } from 'react'
import './Login.css'
import { Button, TextField, Typography } from '@mui/material'
import { IMAGES } from '../../constants/images'
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, mobileNumberRegex, otpRegex, saveData } from '../../constants/global';
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import { setForceLoginPopup, setUserData } from '../../redux/action/UserAction';
import { useDispatch, useSelector } from 'react-redux';
import { Variables } from '../../constants/variable';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';
import Toast from '../../component/Toast';
import { get, ref, update } from 'firebase/database';
import { database } from '../../App';
import { identifyEvent, setAnalyticsRole, setAnalyticsUserName, trackEvents } from '../../constants/analyticsService';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const options = [
    'Student/Intern', 'APM', 'PM', 'SPM', 'Lead PM', 'VP, Product', 'Director, Product', 'Head of Product', 'Others'
];

let TimerInterval;
const Login = () => {
    const history = useHistory()
    const auth = getAuth();
    const dispatch = useDispatch()
    const location = useLocation()

    const [activeIndex, setActiveIndex] = useState(0)
    const [mobileNumber, setMobileNumber] = useState('');
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState('')
    const { userData } = useSelector((state) => state.common);
    const [openLoader, setOpenLoader] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [role, setRole] = useState(localStorage.getItem(Variables.localRole) || "")
    const [otherRole, setOtherRole] = useState('');
    const [otherRoleError, setOtherRoleError] = useState(false)

    const tempId = localStorage.getItem(Variables.tempId)
    const { videoId } = location.state ?? ''

    useEffect(() => {
        if (userData) history.push("/");
    }, []);

    const tempUserRef = useMemo(() => {
        if (tempId) return ref(database, `/tempusers/${tempId}/videosV2/`)
        else return null
    }, [])

    const setupRecaptcha = async (number) => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible'
            });
            const response = await signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
            return response;
        } catch (error) {
            console.log(error, "error")
        }
    }

    const handleMobileNumberChange = (event) => {
        const inputMobileNumber = event.target.value;

        if (mobileNumberRegex.test(inputMobileNumber)) {
            setMobileNumberError(false);
        } else {
            setMobileNumberError(true);
        }

        setMobileNumber(inputMobileNumber);
    };

    const handleUsernameChange = (event) => {
        const inputUsername = event.target.value;
        if (!inputUsername) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }

        setUsername(inputUsername);
    };

    const handleOtherRole = (event) => {
        const inputUsername = event.target.value;
        if (!inputUsername) {
            setOtherRoleError(true);
        } else {
            setOtherRoleError(false);
        }

        setOtherRole(inputUsername);
    };

    const handleOtpChange = (event) => {
        const inputOtp = event.target.value;

        if (otpRegex.test(inputOtp)) {
            setOtpError(false);
        } else {
            setOtpError(true);
        }

        setOtp(inputOtp);
    };

    const onStartTimer = () => {
        clearInterval(TimerInterval);
        setSeconds(60);
        let timer = 60;
        TimerInterval = setInterval(() => {
            if (timer > 0) {
                timer -= 1;
                setSeconds(timer);
            }
            else clearInterval(TimerInterval);
        }, 1000);
    }

    const onClickContinue = async () => {
        if (mobileNumber?.trim() == '' || mobileNumberError) {
            setUsernameError(false);
            return setMobileNumberError(true);
        }
        setOpenLoader(true);
        setMobileNumberError(false)

        try {
            const phoneNumber = '+91' + mobileNumber;
            const response = await setupRecaptcha(phoneNumber)
            onStartTimer();
            setConfirmationResult(response);
            setActiveIndex(activeIndex + 1);
            toast.success('Verification code sent successfully.', {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
            setOpenLoader(false);
        }
        catch (err) {
            console.log(err)
            setTimeout(() => {
                setOpenLoader(false);
                toast.error('Something went wrong! Please try again.', {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }, 1000);
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
    }

    const onVerifyOtp = async () => {
        if (otp?.trim() == '' || !otpRegex.test(otp)) return setOtpError(true);
        setOtpError(false)
        setOpenLoader(true);
        try {
            const res = await confirmationResult.confirm(otp)
            identifyEvent(res?.user?.uid, mobileNumber)
            trackEvents.trackLogin({ isFirstTime: res?._tokenResponse?.isNewUser });
            dispatch(setUserData(res.user))
            await saveData(Variables.userData, res.user)
            dispatch(setForceLoginPopup(false))

            if (tempId) {
                try {
                    const userRef = ref(database, `/users/${res?.user?.uid}`)
                    const newUserData = (await get(userRef)).val()
                    const tempUserData = (await get(tempUserRef)).val()

                    if (tempUserData) {
                        const mergedObject = {
                            likes: { ...newUserData.videosV2.likes, ...tempUserData.likes },
                            opens: { ...newUserData.videosV2.opens, ...tempUserData.opens },
                            views: { ...newUserData.videosV2.views, ...tempUserData.views }
                        };
                        await update(userRef, { videosV2: mergedObject });
                    }
                }
                catch { }
            }

            if (res?._tokenResponse?.isNewUser) {
                setOpenLoader(false);
                setActiveIndex(activeIndex + 1)
                toast.success('Otp verified successfully.', {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
            else {
                setTimeout(() => {
                    setOpenLoader(false);
                    if (videoId) history.push('/' + ROUTES.VIDEO_PLAYING + '/' + videoId, { source: 'Login' })
                    else history.push('/');
                }, 1000);
            }
        }
        catch (err) {
            trackEvents.trackLoginError({ error: err?.code })
            toast.error("Invalid OTP", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
            setTimeout(() => {
                setOpenLoader(false);
            }, 1000);
        }
    }

    const onNameConfirm = async () => {
        if (!role) {
            return toast.error('Please select your role', {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
        }
        if (role == 'Others' && !otherRole) return setOtherRoleError(true)
        if (!username) return setUsernameError(true);
        setOpenLoader(true);
        const userRef = ref(database, `/users/${userData?.uid}`)
        setAnalyticsUserName(userData?.uid, username || "")
        await update(userRef, { name: username, role: role != 'Others' ? role : otherRole });
        trackEvents.trackNameContinue({ name: username || "", role: role != 'Others' ? role : otherRole })
        setAnalyticsRole(userData?.uid, (role != 'Others' ? role : otherRole) || "")
        localStorage.setItem(Variables.localRole, role != 'Others' ? role : otherRole)
        setTimeout(() => {
            setOpenLoader(false);
            if (videoId) history.push('/' + ROUTES.VIDEO_PLAYING + '/' + videoId, { source: 'Login' })
            else history.push('/');
        }, 1000);
    }

    return (
        <div className='main-container'>
            <Toast />
            <Loader open={openLoader} handleClose={() => setOpenLoader(false)} />
            <div className='yellow-container'>
                <img src={IMAGES.login_boy} className='login-boy-style' alt={'login-boy'} />
                <div className='title-white-style'>
                    {`Upskilling platform for PMs`}
                </div>
                <div className='title-white-style'>
                    {`via real-world case-studies`}
                </div>
                <div className='subtitle-red-style'>
                    Get latest case studies from top startups
                </div>
            </div>
            <div className='right-sub-container'>
                <div className='right-inner-container'>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            alt={'Hellomeets-logo'}
                            src={IMAGES.hellomeets_logo}
                            className='hellomeets-logo-style'
                        />
                    </div>
                    {activeIndex === 0 &&
                        <>
                            <Typography variant="h4" style={{ fontWeight: 'bold' }} className='login-title-style'>
                                Sign in to HelloMeets
                            </Typography>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Enter phone number"
                                variant="outlined"
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                                error={mobileNumberError}
                                helperText={mobileNumberError ? 'Please enter a valid mobile number' : ''}
                                onKeyDown={(e) => e.keyCode == 13 && onClickContinue()}
                            />
                            <div id='recaptcha-container' className='vertical-margin-15'></div>
                            <Button
                                onClick={onClickContinue}
                                fullWidth
                                variant="contained"
                                className='button-container-style top-margin-20'
                            >
                                Continue
                            </Button>
                            <Typography variant="body2" className='accept-text-style' >
                                By signing in, you accept our <span className='privacy-style cursor-pointer' onClick={() => window.open('https://www.hellomeets.com/privacy-policy', '_blank')}>Privacy Policy.</span>
                            </Typography>
                        </>
                    }
                    {activeIndex == 1 &&
                        <>
                            <div className='row-flex-view'>
                                <div style={{ cursor: 'pointer' }} onClick={() => setActiveIndex(activeIndex - 1)}>
                                    <img
                                        src={IMAGES.arrow_back}
                                        className='back-arrow-style'
                                        alt={'back-arrow'}
                                    />
                                </div>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }} className='confirm-number-style'>
                                    Confirm your number
                                </Typography>
                            </div>
                            <Typography variant="body1" className='login-subtitle-style'>
                                Enter the code we have sent to {mobileNumber}
                            </Typography>
                            <TextField
                                fullWidth id="outlined-basic"
                                label="Enter OTP"
                                variant="outlined"
                                value={otp}
                                onChange={handleOtpChange}
                                error={otpError}
                                helperText={otpError ? 'Please enter a valid otp' : ''}
                                onKeyDown={(e) => e.keyCode == 13 && onVerifyOtp()}
                            />
                            <Typography variant="body1" className='login-subtitle-style'>
                                {seconds == 0 ?
                                    <> Haven’t received a SMS? <span className='send-again-style' onClick={onClickContinue}>Send again</span>
                                    </>
                                    :
                                    <> Resend otp in <span className='remaining-seconds'>{seconds} seconds.</span>
                                    </>
                                }
                            </Typography>
                            <div id='recaptcha-container' className='vertical-margin-15'></div>
                            <Button
                                onClick={onVerifyOtp}
                                fullWidth variant="contained"
                                className='button-container-style'
                            >
                                Continue
                            </Button>
                        </>
                    }
                    {activeIndex == 2 &&
                        <>
                            <div className='row-flex-view'>
                                <div style={{ cursor: 'pointer' }} onClick={() => setActiveIndex(activeIndex - 1)}>
                                    <img
                                        src={IMAGES.arrow_back}
                                        className='back-arrow-style'
                                        alt={'back-arrow'}
                                    />
                                </div>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }} className='confirm-number-style'>
                                    Enter your details
                                </Typography>
                            </div>

                            <Dropdown
                                value={role}
                                options={options}
                                placeholder="Select your role"
                                onChange={(item) => setRole(item.value)}
                            />
                            {role == 'Others' &&
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Enter your role"
                                    variant="outlined"
                                    className='bottom-margin-20'
                                    value={otherRole}
                                    onChange={handleOtherRole}
                                    error={otherRoleError}
                                    helperText={otherRoleError ? 'Please enter your role' : ''}
                                />
                            }
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Enter full name"
                                variant="outlined"
                                value={username}
                                onChange={handleUsernameChange}
                                error={usernameError}
                                helperText={usernameError ? 'Please enter a full name' : ''}
                                className='bottom-margin-30'
                            />
                            <Button
                                onClick={onNameConfirm}
                                fullWidth variant="contained"
                                className='button-container-style'
                            >
                                Continue
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Login