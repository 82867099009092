import React from 'react';
import './Component.css';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import { MainEventTypes, trackEvents } from '../constants/analyticsService';
import { ROUTES, fetchMarketingUser } from '../constants/global';
import { useLocation, useHistory } from 'react-router-dom';
import { IMAGES } from '../constants/images';

export default function BottomBar() {
    const data = [{
        Icon: HomeIcon,
        title: 'Home',
        route: '/',
        sendEvent: MainEventTypes.HOME_TAB
    }, {
        Icon: HistoryIcon,
        title: 'History',
        route: '/' + ROUTES.HISTORY,
        sendEvent: MainEventTypes.HISTORY_TAB
    }]

    const history = useHistory();
    const location = useLocation();

    const handleSideMenuClick = (item) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        history.push(item.route);
        switch (item.sendEvent) {
            case MainEventTypes.HOME_TAB:
                trackEvents.trackHomeTab();
                break;
            case MainEventTypes.SEARCH_TAB:
                trackEvents.trackSearchTab();
                break;
            case MainEventTypes.HISTORY_TAB:
                trackEvents.trackHistoryTab();
                break;
        }
    }

    return (
        <div className='bottom-tab-main-container'>
            {fetchMarketingUser() &&
                <>
                    <div className='install-app-banner-title'>Enjoy best experience on Hellomeets app</div>
                    <div className='install-banner-container'>
                        <div className='download-app-banner-container' onClick={() => {
                            trackEvents.trackInstallAppAppStoreClick({ source: 'Video' })
                            window.open("https://hellomeets.page.link/qF3jQ43GtJHqEu5k9", "_blank")
                        }}>
                            <img src={IMAGES.apple_store} className='store-banner-logo' />
                            <div>
                                <div className='subtext-banner'>Download on the</div>
                                <div className='store-banner-name'>App Store</div>
                            </div>
                        </div>
                        <div className='download-app-banner-container' onClick={() => {
                            trackEvents.trackInstallAppPlayStoreClick({ source: 'Video' })
                            window.open("https://hellomeets.page.link/qF3jQ43GtJHqEu5k9", "_blank")
                        }}>
                            <img src={IMAGES.google_play_store} className='store-banner-logo' />
                            <div>
                                <div className='subtext-banner'>Download on the</div>
                                <div className='store-banner-name'>Google Play</div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className='bottom-tab-container'>
                {data?.map((item, index) => {
                    const Icon = item.Icon
                    const SelectedItem = location.pathname === item.route
                    return (
                        <div
                            onClick={() => handleSideMenuClick(item)}
                            key={index}
                            className={`single-tab-style`}>
                            <Icon style={{ height: '28px', width: '28px', color: SelectedItem ? 'black' : '#525252' }} />
                            <label className={SelectedItem ? 'side-bar-selected-text' : 'side-bar-text'}>{item?.title}</label>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
