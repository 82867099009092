import { LinearProgress, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES, calculateTimeFormat, encodeFunction } from '../constants/global';
import './Component.css';
import { IMAGES } from '../constants/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { trackEvents } from '../constants/analyticsService';
import LazyLoad from 'react-lazyload';

const VideoComponentCarousel = ({ type, data, displayCount, displayProgressBar, slidesPerView, onClickVideo = () => { } }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        // const nextButton = document.querySelector('.swiper-button-next');
        // const prevButton = document.querySelector('.swiper-button-prev');
        const nextButton = document.querySelector(type == 'TRENDING_VIDEOS' ? '.trending-video-swiper-button-next' : '.continue-watching-swiper-button-next');
        const prevButton = document.querySelector(type == 'TRENDING_VIDEOS' ? '.trending-video--swiper-button-prev' : '.continue-watching-swiper-button-prev');

        if (nextButton) {
            nextButton.addEventListener('click', handleNextClick);
        }
        if (prevButton) {
            prevButton.addEventListener('click', handlePrevClick);
        }
        return () => {
            if (nextButton) {
                nextButton.removeEventListener('click', handleNextClick);
            }
            if (prevButton) {
                prevButton.removeEventListener('click', handlePrevClick);
            }
        };
    }, []);


    const handleNextClick = () => {
        if (type == 'TRENDING_VIDEOS') trackEvents.trackTrendingVideoRightArrowClick();
        else trackEvents.trackContinueWatchingRightArrowClick()
    };

    const handlePrevClick = () => {
        if (type == 'TRENDING_VIDEOS') trackEvents.trackTrendingVideoLeftArrowClick();
        else trackEvents.trackContinueWatchingLeftArrowClick()
    };

    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:767px)');
    const divRef = useRef(null);

    const onSpeakerProfile = (item) => {
        trackEvents.trackClickSpeakerLogo({
            speakerId: item?.speakerId,
            videoId: item?.id,
            speakerName: item?.speakerName || '',
            source: type == 'TRENDING_VIDEOS' ? "Trending Videos" : "Continue Watching"
        })
        history.push('/' + ROUTES.SPEAKER_PROFILE + '/' + encodeFunction(item?.speakerId) + '/' + item?.speakerName?.split(' ')?.join('-')?.toLowerCase(), { source: type == 'TRENDING_VIDEOS' ? "Trending Videos" : "Continue Watching" })
    }

    const swiperRef = useRef(null);

    const isAtStart = activeIndex === 0;
    const isAtEnd = activeIndex >= data?.length - 3;

    return (
        <div style={{ position: 'relative' }}>
            <div ref={divRef}
                style={isMobile && type == 'TRENDING_VIDEOS' ? { paddingBottom: '30px' } : {}}
                className='swiper-wrapper-style'>
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#E5931C",
                    }}
                    ref={swiperRef}
                    pagination={{ clickable: true }}
                    // navigation={true}
                    navigation={{
                        nextEl: type == 'TRENDING_VIDEOS' ? ".trending-video-swiper-button-next" : ".continue-watching-swiper-button-next",
                        prevEl: type == 'TRENDING_VIDEOS' ? ".trending-video-swiper-button-prev" : ".continue-watching-swiper-button-prev",
                        disabledClass: "swiper-button-disabled"
                    }}
                    modules={isMobile ? [Navigation, Pagination] : [Navigation]}
                    slidesPerView={slidesPerView}
                    spaceBetween={isMobile ? 15 : 35}
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                >
                    {data?.map((item, index) => {
                        const totalDuration = item?.viewMeta?.totalDurationSeconds;
                        const durationSeconds = item?.viewMeta?.durationSeconds;
                        const { videoDuration } = item;
                        const topicArray = [item?.topic, ...(item?.subTopic?.split(',') || [])]
                        const finalTopicArray = []
                        topicArray?.map((i) => {
                            if (i) finalTopicArray.push(i)
                        })
                        const displayTopicArray = [...new Set(finalTopicArray)]?.slice(0, 4)

                        return (
                            <SwiperSlide key={index}>
                                <div className={`video-component-container`}>
                                    <div
                                        onClick={() => onClickVideo(item)}
                                        className={`thumbnail-container ${(type == 'CONTINUE_WATCHING') && 'bottom-margin-10'}`}
                                        style={{ borderRadius: isMobile ? '0px' : '12px' }}
                                    >
                                        {displayCount && <div className='count-container'>{`${index + 1}/${data.length}`}</div>}
                                        <LazyLoad height={200} once offset={500}>
                                            <img
                                                src={item?.thumbnail || `https://img.youtube.com/vi/${item?.id}/hqdefault.jpg`}
                                                className='thumbnail-style'
                                            />
                                            <img
                                                src={IMAGES.player_icon}
                                                className={`${type == 'CONTINUE_WATCHING' ? 'width-50' : 'width-70'} play-icon-container`}
                                            />
                                        </LazyLoad>
                                        <div className={`${displayProgressBar ? 'bottom-15' : 'bottom-4'} time-container`}>{calculateTimeFormat(videoDuration)}</div>
                                        {displayProgressBar && <div>
                                            <LinearProgress value={(durationSeconds * 100) / totalDuration} variant="determinate" className={`continue-watching-progress-bar ${isMobile ? 'width-80-per' : 'width-85-per'}`} />
                                        </div>
                                        }
                                    </div>
                                </div>
                                {(type != 'CONTINUE_WATCHING') &&
                                    <div
                                        onClick={() => onClickVideo(item)}
                                        className={`feed-topic-container`}>
                                        {displayTopicArray?.map((i, index) => {
                                            return (
                                                <div className='feed-topic-lable-view' key={index}>
                                                    <label className='feed-topic-label'>{i}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                <div className={`bottom-margin-40`}>
                                    <div className='feed-subtitle-style' onClick={() => onClickVideo(item)}>{item?.subTitle || ''}</div>
                                    <div className='feed-speaker-info'>
                                        <img src={IMAGES.feed_user} className='speaker-icon-style' />
                                        <div onClick={() => onSpeakerProfile(item)} className='feed-speaker-style'>&nbsp;&nbsp;{item?.speakerName || ''},&nbsp;</div>
                                        <div
                                            onClick={() => {
                                                trackEvents.trackClickBrandLogo({
                                                    brandId: item?.brandId,
                                                    videoId: item?.id,
                                                    brandName: item?.brandName || '',
                                                    source: type == 'TRENDING_VIDEOS' ? "Trending Videos" : "Continue Watching"
                                                })
                                                history.push('/' + ROUTES.BRAND_PROFILE + '/' + encodeFunction(item?.brandId) + '/' + item?.brandName?.split(' ')?.join('-')?.toLowerCase(), { source: type == 'TRENDING_VIDEOS' ? "Trending Videos" : "Continue Watching" })
                                            }}
                                            className='feed-speaker-style'>{item?.brandName || ''}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                {!isMobile &&
                    <>
                        <div className={type == 'TRENDING_VIDEOS' ? 'trending-video-swiper-button-next' : 'continue-watching-swiper-button-next'}>
                            <img
                                className='arrow-image-style'
                                src={IMAGES.swiper_right_arrow} />
                        </div>
                        <div className={type == 'TRENDING_VIDEOS' ? 'trending-video-swiper-button-prev' : 'continue-watching-swiper-button-prev'}>
                            <img
                                className='arrow-image-style'
                                src={IMAGES.swiper_left_arrow} />
                        </div>
                    </>
                }
            </div>
            {!isMobile &&
                <>
                    {!isAtStart &&
                        <img
                            src={type == 'TRENDING_VIDEOS' ? IMAGES.left_shadow : IMAGES.left_gray_shadow}
                            className='left-shadow-style'
                        />
                    }
                    {!isAtEnd &&
                        <img
                            src={type == 'TRENDING_VIDEOS' ? IMAGES.right_shadow : IMAGES.right_gray_shadow}
                            className='right-shadow-style'
                        />
                    }
                </>
            }
        </div>
    )
}

export default VideoComponentCarousel;