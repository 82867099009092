import { combineReducers } from "redux";
import CommonReducer from "./CommonReducer";
import { USER_LOGGING_OUT } from "../config/actionTypes";

const rootReducer = combineReducers({
  common: CommonReducer
});

const appReducer = (state, action) => {
  if (action.type == USER_LOGGING_OUT) {
    state = undefined
  }
  return rootReducer(state, action);
};

export default appReducer;
