import { CONTINUE_WATCHING, DRAWER_OPEN, FORCE_LOGIN_POPUP, HISTORY_PAGE_VIDEO, HISTORY_VIDEO_LOADING, HOME_PAGE_VIDEO, HOME_VIDEO_LOADING, PILLS_DATA, PLAYLISTS, RECOMMENDATION, SEARCH_PAGE_DATA, SEARCH_PAGE_DATA_LOADING, SOFT_LOGIN_POPUP, TOPIC_EXPANDED, TRENDING_VIDEO, USER_DATA, USER_INFO } from "../config/actionTypes";

const initialState = {
    userData: '',
    homePageVideo: [],
    historyPageVideo: [],
    homeVideoLoading: false,
    historyVideoLoading: false,
    userInfo: '',
    pillsData: [],
    trendingVideos: [],
    continueWatchingVideos: [],
    searchPageData: '',
    searchPageDataLoading: false,
    forceLoginPopup: false,
    softLoginPopup: false,
    drawerOpen: false,
    recommendation: false,
    playlists: [],
    topicExpanded: false
};

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_DATA: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case HOME_PAGE_VIDEO: {
            return {
                ...state,
                homePageVideo: action.payload,
            };
        }
        case HISTORY_PAGE_VIDEO: {
            return {
                ...state,
                historyPageVideo: action.payload,
            };
        }

        case HOME_VIDEO_LOADING: {
            return {
                ...state,
                homeVideoLoading: action.payload,
            };
        }

        case HISTORY_VIDEO_LOADING: {
            return {
                ...state,
                historyVideoLoading: action.payload,
            };
        }

        case USER_INFO: {
            return {
                ...state,
                userInfo: action.payload,
            };
        }

        case PILLS_DATA: {
            return {
                ...state,
                pillsData: action.payload,
            };
        }

        case TRENDING_VIDEO: {
            return {
                ...state,
                trendingVideos: action.payload,
            };
        }

        case CONTINUE_WATCHING: {
            return {
                ...state,
                continueWatchingVideos: action.payload,
            };
        }

        case SEARCH_PAGE_DATA: {
            return {
                ...state,
                searchPageData: action.payload,
            };
        }

        case SEARCH_PAGE_DATA_LOADING: {
            return {
                ...state,
                searchPageDataLoading: action.payload,
            };
        }
        case FORCE_LOGIN_POPUP: {
            return {
                ...state,
                forceLoginPopup: action.payload,
            };
        }

        case SOFT_LOGIN_POPUP: {
            return {
                ...state,
                softLoginPopup: action.payload,
            };
        }

        case DRAWER_OPEN: {
            return {
                ...state,
                drawerOpen: action.payload,
            };
        }

        case TOPIC_EXPANDED: {
            return {
                ...state,
                topicExpanded: action.payload,
            };
        }

        case RECOMMENDATION: {
            return {
                ...state,
                recommendation: action.payload,
            };
        }

        case PLAYLISTS: {
            return {
                ...state,
                playlists: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default CommonReducer;
