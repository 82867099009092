import { getThumbnail } from "../../constants/global";
import { getHistoryPageVideoApiCall } from "../CommonService";
import { HISTORY_PAGE_VIDEO, HISTORY_VIDEO_LOADING } from "../config/actionTypes";

export const getHistoryData = (query) => {
    return (dispatch) => {
        dispatch(setHistoryVideoLoading(true))
        getHistoryPageVideoApiCall(query)
            .then((res) => {
                let newArray = []
                res.videos.map((item, index) => {
                    if (item?.id) {
                        newArray.push({
                            ...item,
                            // 'thumbnail': getThumbnail(index)
                        })
                    }
                })
                dispatch(setHistoryData(newArray))
                dispatch(setHistoryVideoLoading(false))
            })
            .catch(() => {
                dispatch(setHistoryData([]))
                dispatch(setHistoryVideoLoading(false))
            })
    };
};

export const setHistoryData = (payload) => {
    return { type: HISTORY_PAGE_VIDEO, payload: payload };
};

export const setHistoryVideoLoading = (payload) => {
    return { type: HISTORY_VIDEO_LOADING, payload: payload };
};

