import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const CategorySkeleton = () => {

    return (
        <Grid container spacing={6}>
            {Array(4).fill()?.map((_, index) => {
                return (
                    <Grid
                        key={index}
                        item sm={2.2}>
                        <Skeleton
                            variant="rectangular"
                            className='category-skeleton-container'
                        />
                        <Skeleton
                            variant="rectangular"
                            className='chip-skeleton-container'
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default CategorySkeleton