import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from '../page/Login/Login';
import Home from '../page/Home/Home';
import { ROUTES, softLoginCount } from '../constants/global';
import HistoryScreen from '../page/History/HistoryScreen';
import Profile from '../page/Profile/Profile';
import VideoPlaying from '../page/Home/VideoPlaying';
import SearchResult from '../page/Search/SearchResult';
import { Variables } from '../constants/variable';
import SpeakerProfile from '../page/Profile/SpeakerProfile';
import BrandProfile from '../page/Profile/BrandProfile';
import SoftLogin from '../component/SoftLogin';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen, setSoftLoginPopup } from '../redux/action/UserAction';
import { trackEvents } from '../constants/analyticsService';
import { useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom';
import PrivacyPolicy from '../page/Login/PrivacyPolicy';
import SearchFeed from '../page/Search/SearchFeed';

export const AppRoutes = () => {
    const dispatch = useDispatch();
    const currentViewVideos = localStorage.getItem(Variables.viewVideo) || 0;
    const { userData } = useSelector((state) => state.common);
    const isMobile = useMediaQuery('(max-width:767px)');
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (location.pathname?.includes('blog')) {
            trackEvents.trackDifferentSiteUser({ path: location.pathname })
            window.location.href = 'https://hellomeets.com/'
        }
        else {
            const value = params.get('utm_source')
            if (location.pathname != ('/' + ROUTES.PRIVACY_POLICY)) trackEvents.trackOpenApp(value)
            if (!isMobile) dispatch(setDrawerOpen(!location.pathname?.includes('video')))
        }
    }, [])

    useEffect(() => {
        if (!userData && currentViewVideos >= softLoginCount) dispatch(setSoftLoginPopup(true))
        else dispatch(setSoftLoginPopup(false))
    }, [currentViewVideos])

    return (
        <Router>
            <Route path={'/'} exact component={Home} />
            <Route path={'/' + ROUTES.SEARCH_FEED} exact component={SearchFeed} />
            <Route path={'/' + ROUTES.HISTORY} exact component={HistoryScreen} />
            <Route path={'/' + ROUTES.PROFILE} exact component={Profile} />
            <Route path={'/' + ROUTES.SPEAKER_PROFILE + '/:id' + '/:name'} exact component={SpeakerProfile} />
            <Route path={'/' + ROUTES.BRAND_PROFILE + '/:id' + '/:name'} exact component={BrandProfile} />
            <Route path={'/' + ROUTES.VIDEO_PLAYING + '/:id'} exact component={VideoPlaying} />
            <Route path={'/' + ROUTES.SEARCH_RESULT + '/:id' + '/:name'} exact component={SearchResult} />
            <Route path={'/' + ROUTES.LOGIN} exact component={Login} />
            <Route path={'/' + ROUTES.PRIVACY_POLICY} exact component={PrivacyPolicy} />
            <SoftLogin />
        </Router>
    )
}
